.settingsGrid {
  padding: 20px !important;
  height: 100vh;
}

.gridItem {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px;
}

.item {
  height: 74px;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .settingsGrid {
    padding: 20px !important;
    flex-direction: column !important;
    gap: 20px;
    padding-top: 0px !important;
    padding-left: 10px !important;
  }

  .gridItem {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    padding-top: 12px !important;
    max-width: 100% !important;
  }

  .item {
    width: 100%;
  }

  .buttonContainer {
    width: 124px;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .settingsGrid {
    padding: 20px !important;
    flex-direction: column !important;
    gap: 20px;
    padding-top: 0px !important;
    padding-left: 10px !important;
  }

  .gridItem {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    padding-top: 12px !important;
    max-width: 100% !important;
  }

  .item {
    width: 100%;
  }

  .buttonContainer {
    width: 124px;
  }
}
