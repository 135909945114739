.deleteButton {
  display: flex;
  padding: 13px 13px 13px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #fff !important;
  background-color: #ffffff0a !important;
  color: #551ba0 !important;
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border: none;
  cursor: pointer;
}
.myTaskButton {
  display: flex;
  padding: 13px 20px 13px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #551ba0;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border: none;
  cursor: pointer;
}

.myTaskButton:hover {
  background-color: #551ba0;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.secondaryContainer {
  width: 100%;
  padding: 20px 0px !important;

  border-radius: 4px;
}
.innerContainer {
  border-radius: 4px;
  width: 100%;
  margin: 0px;
  padding: 0px !important;
}
.secondInnerContainer {
  display: flex !important;
  margin-top: 20px;
  padding: 0px !important;
  justify-content: space-between;
  flex-direction: row;
  overflow-x: scroll;
  gap: 20px;
  /* width: 100%; */
}
.filterText {
  color: var(--neutrals-muted, #77767c) !important;

  /* H5/bold */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 98% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 98% !important;
    background-color: pink;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
}
