.attachedDocument {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 20px;
  margin-top: 1vh;
  height: 100px;
  background-color: #EDFFD8;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px ;
}

.name {
  text-align: left;
  font-family: "Red Hat Display" !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #201F28!important;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  width: 62px;
}

.imageContainer img {
    height: 40px !important;
    width: 40px !important;
}

.description {
  text-align: left;
  width: 100%;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #636269 !important;
}

.unverifiedDocument {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 16px !important;
}

.closeIcon {
  color: #ff974c;
  height: 20px !important;
  width: 20px !important;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .attachedDocument {
    width: 90%;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .attachedDocument {
    width: 90%;
  }
}
