.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  align-self: stretch;
}
.title {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.flexRow {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
