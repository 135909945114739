.dialogueTitle {
  color: #fff !important;
  text-align: center !important;
  /* H2/medium */
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important; /* 133.333% */
}
.headerContainer {
  display: flex;
  padding: 40px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.scheduleContainer {
  border-radius: 12px;
  background: var(--theme-dark-0, #222224);
  padding: 100px;
}
.dialogue {
  min-width: 700px !important;
  border-radius: 12px;
  color: black;
}

.cancelButton {
  display: flex;
  height: 48px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  background: transparent;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: var(--light-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}

.scheduleButton {
  height: 40px !important;
  width: 147px !important;
  background-color: #464092 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  text-transform: none !important;
}
.sectionHeader {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 100% */
  letter-spacing: 1.6px !important;
  text-transform: uppercase !important;
}
.buttonContainer {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  border-top: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
*::-webkit-scrollbar {
  display: none;
}
.createButton {
  display: flex;
  height: 48px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-background, #551ba0);
  color: var(--light-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  .dialogueTitle {
    font-size: 20px !important;
  }
}
