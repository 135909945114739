.container {
  position: relative;
  border-radius: 20px;
  background: #030204;
  display: flex;
  margin-bottom: 4vh;
  height: 88vh;
  overflow-y: auto;
  width: 98%;
  align-items: flex-start;
  align-self: stretch;
  /* padding-bottom: 1vh; */
}
.container::-webkit-scrollbar {
  display: none;
}
