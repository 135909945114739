.container {
  display: flex;
  /* width: 95%; */
  padding: 16px;
  flex-direction: column;
  /* gap: 12px; */
  /* background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04)); */
  /* Elevations/E2 */
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1); */
}
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}
.type {
  color: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24)) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 12px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
}
.headingFlex {
  display: flex;
  align-items: end;
  /* justify-content: space-between; */
}
.mainContainer {
  border-radius: 12px !important;
  width: 100%;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.title {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 12px !important;
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
}
.headingContainer {
  border-radius: 12px 12px 0px 0px;
  display: flex;
  padding: 12px;
  justify-content: space-between;
  cursor: pointer;

  align-items: center;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
}
.iconContainer {
  display: flex;
  width: 20%;
  justify-content: space-between;
}
.description {
  color: #ffffffcc;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi Variable;
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 18px; /* 150% */
  letter-spacing: 0.25px;
}
.title {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Captiol Heading 50 */
  font-family: Satoshi Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.timeStamp {
  color: rgba(255, 255, 255, 0.5) !important;

  /* Capitol Heading 25 */
  font-family: Satoshi Variable;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-align: left !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.iconStyles {
  width: 20px;
  cursor: "pointer";
}

@media screen and (max-width: 1230px) {
  .title {
    font-size: 10px !important;
    margin-bottom: 05px !important;
  }
  .description {
    font-size: 10px !important;
  }
  .container {
    padding: 8px;
    gap: 6px;
  }
  .type {
    font-size: 10px !important;
  }
  .iconContainer {
    width: auto;
  }
  .headingContainer {
    padding: 8px;
  }
  .iconStyles {
    width: 50px;
  }
}
