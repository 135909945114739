.container {
  margin: 0px;
  display: flex;
  width: 100%;
}
.wrapper1 {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-top: 3vh;
  padding-left: 20px;
  padding-right: 20px;
  gap: 3vh;
}
.wrapper2 {
  width: 75%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    #151515 -122.7%,
    rgba(73, 73, 73, 0) 45.67%
  );
  /* background: var(--dark-inverted-bg-8, rgba(3, 2, 4, 1)); */
}

.title {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  display: flex;
  line-height: 28px !important;
  letter-spacing: -0.48px !important;
}

.subTitle {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 14px !important;
}

.text {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 16px !important;
  display: flex;
  margin-top: 4px;
}

.card {
  width: 100%;
  padding: 20px;
  padding: 20px;
  border-radius: 10px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;
  margin-top: 20px;
}

.cardWrapper {
  display: flex;
  width: 72px;
  height: 72px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Golden-Purple-8, #391085);
}

.insideTitle {
  color: var(--Primary-Light, #cfa4f6);
  font-family: "Satoshi Variable";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.cardWrapper {
  display: flex;
  width: 72px;
  height: 72px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Golden-Purple-8, #391085);
}

.btn {
  display: flex;
  padding: 13px 20px 13px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background: #551ba0;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.cardTitle {
  color: var(--Neutral-1, #fff);
  /* H4/Bold */
  font-family: "Satoshi Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px; /* 140% */
  display: flex;
  margin-top: 20px;
}

.cardText {
  display: flex;
  color: #fff;
  /* H4/medium */
  font-family: "Satoshi Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.cardText2 {
  display: flex;
  color: #fff;
  /* H4/medium */
  font-family: "Satoshi Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.cardLiteText {
  display: flex;
  color: rgba(255, 255, 255, 0.4);
  /* H4/medium */
  font-family: "Satoshi Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
