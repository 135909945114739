.inMeetingContainer {
  width: 100%;
  background-color: #ffffff;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0px !important;
  gap: 16px;
  position: relative;
}

.topBar {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999 !important;
  padding: 16px !important;
  background-color: #4a42ad;
}

.meetingTitle {
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.tabsContainer {
  height: 100vh;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 16px 0px 0px 0px !important;
  gap: 16px;
}

.promptTitle {
  font-size: 18px !important;
  font-family: "Red Hat Display" !important;
  color: #201f28 !important;
  font-weight: 500 !important;
  width: 90%;
  text-align: center;
}

.promptSubtext {
  font-size: 12px !important;
  font-family: "Satoshi-Variable" !important;
  color: #77767c !important;
  font-weight: 500 !important;
  width: 80%;
  text-align: center;
}

.textField {
  height: 90px !important;
  background-color: #fafafa;
  border: none;
  outline: none;
  padding: 8px !important;
  font-size: 12px !important;
  font-family: "Satoshi-Variable" !important;
  color: #201f28 !important;
  font-weight: 500 !important;
  resize: none;
}

.textField::placeholder {
  font-size: 12px !important;
  font-family: "Satoshi-Variable" !important;
  color: #a6a5a9 !important;
  font-weight: 500 !important;
}

.submitButton {
  background-color: #4a42ad !important;
  color: #ffffff;
  font-size: 12px !important;
  font-family: "Satoshi-Variable" !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  text-transform: none !important;
  width: 110px;
  height: 40px;
  border-radius: 4px !important;
}

.skip {
  font-size: 12px !important;
  font-family: "Satoshi-Variable" !important;
  color: #636269 !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
