.listContainer {
    width: 100%;
    padding: 0px !important;
    margin-top: 0 !important;
}

.unorderedList {
    padding-left: 17px;
}

.listItem {
    margin-top: 7px;
    margin-left: 5px;
}

.addNote {
    padding-left: 0px;
    margin-left: 0px;
    display: flex;
    justify-content: left;
    align-items: left;
    width:100%;
    text-align: left;
    font-size: 14px;
    color: #A6A5A9;
    cursor: pointer;
    font-weight: 500;
}