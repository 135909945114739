.container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dividerColor {
  background-color: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.14));
  height: 1px;
  width: 35%;
}
.text {
  color: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24)) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.105px !important;
}
