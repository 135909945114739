.container {
  display: flex;
  gap: 12px;
  align-items: center;
}
.valueText {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: right !important;
  text-overflow: ellipsis !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
.title {
  color: rgba(255, 255, 255, 0.8) !important;
  text-overflow: ellipsis !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
