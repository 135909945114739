.cardContainer {
  width: 95%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}
.title {
  color: var(--neutral-1, #fff) !important;

  /* H4/Bold */
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 140% */
}
.subTitle {
  color: #c3c2c5 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
