.container {
  display: flex;
  padding: 14px 0px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.00));
}
.valueText {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: center !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
.index {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 160% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
