.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  background: linear-gradient(
    133deg,
    #51ffe0 -86.89%,
    rgba(114, 128, 179, 0.28) -20.19%,
    rgba(97, 189, 255, 0) 43.79%
  );
  align-self: stretch;
}
.userName {
  color: #fff !important;
  text-align: left !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 40px !important; /* 133.333% */
}
.userTitle {
  color: rgba(255, 255, 255, 0.7) !important;
  text-align: left !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
}
.flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexRow {
  display: flex;
  gap: 8px;
}
.badgeContainer {
  display: flex;
  padding-bottom: 10px;

  margin-bottom: 20px;
  max-width: 100%;
  /* background: #9747ff; */
  overflow-x: auto;
  gap: 8px;
}
.userImage {
  width: 44px;
  height: 44px;
  border-radius: 44px;
}
