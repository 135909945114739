.container {
  display: flex;
  padding: 3vh 1vw;

  flex-direction: column;
  align-items: flex-start;
  gap: 4vh;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  align-self: stretch;
}
.managerContainer {
  display: flex;
  padding: 3vh 1vw;

  flex-direction: column;
  align-items: flex-start;
  gap: 4vh;
  border-radius: 12px;
  align-self: stretch;
}
.flexRow {
  display: flex;
  gap: 1.2vw;
  align-items: center;
}
.flexColumn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.iconContainer {
  display: flex;
  padding: 11px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.title {
  color: var(--golden-purple-1, #f9f0ff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  opacity: 0.8 !important;
  /* Captiol Heading 50 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.titleInfo {
  color: #fff !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 120% */
}
