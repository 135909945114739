.container {
  display: flex;
  width: 80%;

  padding: 16px;
  /* margin-top: 25px; */
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.titleContainer {
  display: flex;

  align-items: end;
}
.description {
  color: #ffffff40 !important;

  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi Variable;
  font-size: 12px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 150% */
  letter-spacing: 0.25px !important;
}
.title {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  margin-bottom: 15px !important;
  /* Captiol Heading 50 */
  font-family: Satoshi Variable;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.timeStamp {
  color: #ffffff40 !important;
  margin-left: 10px !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 171.429% */
  letter-spacing: 0.25px !important;
}
@media screen and (max-width: 1230px) {
  .title {
    font-size: 8px !important;
    margin-bottom: 05px !important;
  }
  .description {
    font-size: 10px !important;
  }
  .container {
    padding: 8px;
    gap: 6px;
  }
}
