.container {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  justify-content: space-between;
}
.transcriptText {
  color: #fff !important;
  font-size: 14px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}
