.tableContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  border-radius: 4px;
  padding: 0px !important;
  height: fit-content;
  box-shadow: none;
}

.headerContainer {
  padding: 20px 30px 30px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #030204 !important;
  gap: 10px;
  /* justify-content: space-between; */
  align-items: center;
  border-radius: 4px;
}
.filterText {
  color: var(--neutrals-muted, #77767c) !important;

  /* H5/bold */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.headerTitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
  background: #030204 !important;
  text-align: left !important;
  padding-left: 30px;
  padding-bottom: 2px;
}

.btn {
  width: 100%;
  border: 1.5px solid #e9e9e9 !important;
  color: #77767c !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.btn:hover {
  background-color: inherit !important;
}

.btnSelected {
  width: 100%;
  background-color: #eeedf7 !important;
  border: 1.5px solid #e9e9e9 !important;
  color: #3b358a !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.tableSection {
  width: 100%;
  padding: 0; /* padding: 0 10px 10px 10px; */
  height: 650px;
  overflow-y: scroll;
}
.tableSection::-webkit-scrollbar {
  display: none;
}

td * {
  font-family: "Satoshi-Variable" !important;
  font-size: 14px;
}

.tableSection table {
  border-collapse: collapse;
  table-layout: auto !important;
  width: 100%;
  padding: 0;
}

thead {
  text-align: left;
  margin: 0;
  position: sticky;
  top: 0;
  background: var(--light-inverted-bg-24, rgba(20, 20, 21)) !important;
  z-index: 1;
  /* background: var(--light-inverted-bg-24, rgba(20, 20, 21, 0.24)) !important; */
}

thead th {
  text-transform: uppercase;
  font-size: 11px !important;
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important; /* 160% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}

.tbody {
  width: 100%;
}

td {
  /* padding-right: 20px !important; */
  text-align: end;
  height: 80px;
  color: rgba(255, 255, 255, 0.8) !important;

  /* Body/medium */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 128.571% */
}

tr {
  border-bottom: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16)) !important;
}

.rankedTable td {
  /* padding: 10px;
  padding-right: 40px !important; */
}

.rankedTable td:nth-child(1) {
  padding-right: 0px !important;
}

.fallbackContainer {
  width: 100%;
  padding: 0px;
  height: 550px;
  box-shadow: none;
  background-color: none;
}

.prospectRefresh {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  font-family: "Satoshi-Variable";
  color: #cc7639;
}

.refreshIcon {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  table {
    width: 150% !important;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1066px) {
  table {
    width: 150% !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  table {
    width: 150% !important;
  }
}
