.dialogueTitle {
  font-family: "Red Hat Display" !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #201f28 !important;
}
.dialogue {
  min-width: 700px !important;
  /* background-color: #0000000a; */
}

.cancelButton {
  height: 40px !important;
  width: 77px !important;
  background-color: #f6f6f6 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #201f28 !important;
  text-transform: none !important;
}

.scheduleButton {
  height: 40px !important;
  width: 147px !important;
  background-color: #464092 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  text-transform: none !important;
}
