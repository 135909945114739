.container {
  margin: 0px;
  background-color: none;
  width: 100%;
}
.screenContainer {
  padding: 2vh 0vw 5vh 0vw !important;
  margin: 0px 0px 0px 5vw !important;
}
.graphContainer {
  margin-top: 3vh;
  width: 100%;
  /* padding: 30px; */
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
}

.myTaskButton {
  background-color: #eeedf7 !important;
  color: #201f28 !important;
  width: 128px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.leaderBoardButton {
  background: linear-gradient(
    233.14deg,
    #fff5ed 0.08%,
    #eeedf7 100%
  ) !important;
  color: #201f28 !important;
  width: 142px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  border: 1px dashed #ff974c !important;
  box-shadow: none !important;
}

.headerButtonGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}

.myTaskButton:hover {
  background-color: inherit;
}

.analyticsContainerFlex {
  padding: 1vw 0px 0vw 0vh !important;
  display: flex !important;
  justify-content: space-between;
}

.summary {
  width: 684px;
}

.topFeedbacks {
  width: 480px;
}

.dataTableContainer {
  padding: 0px !important;
  margin-top: 50px;
}

@media only screen and (max-width: 1200px) and (min-width: 1065px) {
  .goodMorningText {
    font-size: 20px !important;
  }

  .upcomingContainer {
    padding-bottom: 5px !important;
  }

  .upcomingText {
    font-size: 18px !important;
  }

  .moreMeeting {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .analyticsContainerFlex {
    flex-wrap: wrap;
  }

  .summary {
    height: 100%;
    width: 82vw;
  }

  .topFeedbacks {
    width: 82vw;
    margin-top: 5vh;
  }

  .tableStack {
    width: 90%;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .analyticsContainerFlex {
    flex-wrap: wrap;
  }

  .summary {
    height: 100%;
  }

  .topFeedbacks {
    width: 90vw;
    margin-top: 5vh;
  }

  .tableStack {
    width: 90%;
  }
}
