.verificationContainer {
  height: 100vh;
  width: 100vw;
  background-color: #e5e5e5;
  display: flex !important;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 64px;
}

.header {
  width: 85%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 3vh !important;
  z-index: 1;
  padding-left: 0px;
  padding-right: 0px;
}

.headerButton {
  background-color: #e9e9e9 !important;
  text-transform: none !important;
  color: #201f28 !important;
  border-radius: 4px;
}

.headerText {
  font-family: "Satoshi-Variable" !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #77767c !important;
}

.headerButtonContainer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
}

a {
  color: #3b358a;
}

.headingText {
  margin-top: 32px !important;
  color: #201f28 !important;
  font-family: "Red Hat Display" !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.information {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.subText {
  color: #77767c !important;
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;
  text-align: center;
  width: 30%;
}

.continueButton {
  width: 378px;
  margin-top: 32px !important;
  height: 56px !important;
  background-color: #4a42ad !important;
  text-transform: none !important;
  color: #fff !important;
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
