.container {
  height: 100%;
  padding-bottom: 150px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.userName {
  color: #fff !important;
  text-align: left !important;

  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 40px !important; /* 133.333% */
}
.userTitle {
  color: rgba(255, 255, 255, 0.7) !important;
  text-align: left !important;

  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
}
.dashboardContainer {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  /* padding: 40px; */
  background: #030204;
  margin-top: 30px;
}
.userInfo {
  width: 27%;
  padding: 40px;
  height: 100%;
  border-radius: 8px;
  /* border-right: 2px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)); */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 200px;
}
.pageTitle {
  color: #f9f0ff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important; /* 114.286% */
  letter-spacing: -0.56px !important;
}
.userPage {
  display: flex;
  padding-top: 40px;
  width: 70%;
  padding-left: 40px;
  padding-right: 20px;
  /* height: 820px; */
  overflow: auto;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.innerContainer {
  background: linear-gradient(
    144deg,
    #9747ff -29.36%,
    rgba(100, 0, 229, 0.27) 1.46%,
    rgba(100, 0, 229, 0) 31.03%
  );
  height: 100%;
}
.levelContainer {
  display: flex;
  padding: 16px;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.levelTitle {
  color: #fff !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 142.857% */
}
.levelSuggestion {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}
.badgeContainer {
  display: flex;
  padding-bottom: 10px;
  width: 90%;
  margin-bottom: 20px;
  max-width: 100%;
  /* background: #9747ff; */
  overflow-x: auto;
  gap: 8px;
}
.progressContainer {
  display: flex;
  width: 90%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.progressStatusContainer {
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexRow {
  display: flex;
  gap: 8px;
}
.allButton {
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 1.4px !important;
  text-transform: uppercase !important;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dashboardTitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 30px !important;
  margin-left: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important; /* 133.333% */
  letter-spacing: -0.6px !important;
}
.mainContainer {
  width: 90%;
  margin: auto;
  padding-top: 50px;
}
