.container {
  display: flex !important;
  margin-top: 2vh !important;
  width: 100%;
  padding: 20px 15px !important;
  /* flex-direction: column !important; */
  justify-content: flex-start !important;
  align-items: center !important;

  border-bottom: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.innerContainer {
  display: flex !important;

  width: 100%;

  /* flex-direction: column !important; */
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 20px !important;
}
.timeStamp {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.title {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
  text-align: left !important;

  letter-spacing: 0.25px !important;
}
.suggestion {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 14px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 0.25px !important;
}
@media screen and (max-width: 1230px) {
  .title {
    margin-bottom: 2px;
    line-height: 12px !important; /* 114.286% */
    font-size: 12px !important;
  }
  .suggestion {
    font-size: 11px !important;
    line-height: 12px !important;
  }
}
