.container {
  display: flex;
  width: 186px;
  /* height: 10vh; */
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.title {
  color: rgba(255, 255, 255, 0.5) !important;

  /* Capitol Heading 25 */
  font-size: 10px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.valueText {
  color: #fff !important;

  /* H2/medium */
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important; /* 133.333% */
}
.subtitle {
  color: rgba(255, 255, 255, 0.4) !important;

  /* H4/medium */
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  bottom: 0;
  line-height: 28px !important;
  margin: auto 0;
}
.ttlrcolon {
  color: rgba(255, 255, 255, 0.4);

  /* H2/medium */
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
