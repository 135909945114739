.container {
    margin: 0px;
    display: flex;
    width: 100%;
  }
  .container1 {
    padding: 0vh 0px 0 20px !important;
    margin: 0px;
    height: 100%;
  
  }
  .wrapper1 {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding-top: 3vh;
    padding-left: 20px;
    padding-right: 20px;
    gap: 3vh;
  }
  .wrapper2 {
    width: 75%;
    height: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #151515 -122.7%, rgba(73, 73, 73, 0) 45.67%);
    /* background: var(--dark-inverted-bg-8, rgba(3, 2, 4, 1)); */
  }
  .teamwrapper {
   
    height: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    background: linear-gradient(90deg, #151515 -122.7%, rgba(73, 73, 73, 0) 45.67%);
    /* background: var(--dark-inverted-bg-8, rgba(3, 2, 4, 1)); */
  }

  .title{
    color: var(--neutral-1, #fff) !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    display: flex;
    line-height: 28px !important;
    letter-spacing: -0.48px !important;
  }
  
  .subTitle{
    color:rgba(255, 255, 255, 0.5) ;
    font-size: 14px !important;
  }
  
  .text{
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 16px !important;
    display: flex;
    margin-top: 4px;
  }
  
  .card {
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
    margin-top: 20px;
  }
  
  
.btn {
  display: flex;
  padding: 13px 20px 13px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background: #551ba0;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
  
  .tabsContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #141415;
  }

  .tabsWrapper {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }