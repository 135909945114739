.tabelHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.title {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 14px !important;
}

.text {
  color: rgba(255, 255, 255, 0.991);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Satoshi Variable";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 160% */
  letter-spacing: 1px;
}
.invite_button{
  display: flex;
  padding: 5px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: #551ba0;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.mediumText {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  text-overflow: ellipsis;
  font-family: "Satoshi Variable";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.actionText {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Satoshi Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.largeText {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  text-overflow: ellipsis;
  font-family: "Satoshi Variable";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.avatar {
  color: var(--Primary-Light, #cfa4f6);
  font-family: "Satoshi Variable";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}
