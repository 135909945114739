.scheduleMeetingFormContainer {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  /* padding: 0px 4px !important; */
  gap: 16px !important;
  margin-top: 2vh;
}
.sectionHeader {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 100% */
  letter-spacing: 1.6px !important;
  text-transform: uppercase !important;
}
.formRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* padding: 0px 0px !important; */
  gap: 20px;
}

.lightText {
  font-family: "Satoshi-Variable" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #77767c !important;
}

.addDescription {
  background-color: #fafafa;
  height: 30px;
  width: 143px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px;
  padding: 4px 8px 4px 8px !important;
  border-radius: 4px;
}

.addDescriptionText {
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #77767c !important;
}

.addIcon {
  color: #77767c !important;
  height: 16px;
  width: 16px;
}

.fieldIcon {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Captiol Heading 50 */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}

.spacerRow {
  margin-top: 2vh;
  width: 100%;
}

.spacerTitle {
  font-family: "Red Hat Display" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #201f28 !important;
  text-align: left;
}
