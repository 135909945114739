.container {
  padding: 0vh 0px 0 20px !important;
  margin: 0px;
  display: flex;
  /* height: 100%; */
  width: 100%;
}
.menuSection {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-top: 3vh;
  padding-right: 20px;
  gap: 3vh;
   border-right: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
}
.settingSection {
  width: 50%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
   border-right: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
}
.notificationSection {
 
  height: 100%;
  padding: 32px;
  width:25%;
  display: flex;
  flex-direction: column;
}
.sectionHeaderContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: stretch;
}
.sectionHeader {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.dateText {
  color: rgba(255, 255, 255, 0.8) !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
.dateSubtitle {
  color: rgba(255, 255, 255, 0.5) !important;

  /* Footnote/description */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 166.667% */
}
.dateContainer {
  border-radius: 5px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  padding: 8px;
}
.statisticsInnerContainer {
  display: flex;
  padding: 3vh 0px;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 6vh;
  align-self: stretch;
}
.searchTitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.searchBarContainer {
  display: flex;
  padding: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.flexRow {
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.dataTableContainer {
  margin-top: 3vh;
}
.searchInput {
  width: 100%;
  background: transparent;
  padding: 4px;
  color: #c3c2c5 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  /* Elevations/E2 */
}
.scheduleButton {
  display: flex;
  padding: 13px 20px 13px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background: #551ba0;

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.viewEngagementButton {
  display: flex;
  padding: 13px 20px 13px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-radius: 8px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
}
.searchInput::placeholder {
  color: #c3c2c5 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.scheduleButton:hover {
  background: #551ba0;
}
.stats {
    display: flex;
    width: 186px;
    padding: 12px;
    height: 160px;
    align-items: flex-start;
    flex-wrap: wrap;
    gap:5px;
    border-radius: 8px;
    background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
   
}
.notification {
  display: flex;
  width: 100%;
  padding: 12px;
  height: 120px;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap:5px;
  border-radius: 8px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
 
}
.chip{
overflow: hidden;
color: var(--dark-inverted-bg-100, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
text-overflow: ellipsis;
font-family: "Satoshi Variable";
font-size: 8px;
font-style: normal;
font-weight: 700;
line-height: 14px; /* 175% */
letter-spacing: 0.8px;
text-transform: uppercase;
}


.stats > h3 {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left !important;
  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.notification > h3 {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left !important;
  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.statsValue {
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.statsValueNumber {
  color: #fff !important;

  /* H5/medium */
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .settingSection {
    height: unset !important;
  }

  .menuSection {
    height: unset !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .menuSection {
    width: 50% !important;
  }
  .settingSection {
    height: unset !important;
  }

  .menuSection {
    height: unset !important;
  }
}
