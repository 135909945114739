.sectionHeader {
  color: #fff !important;
  text-align: center !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important; /* 125% */
}
.sectionDescription {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  opacity: 0.8;
}
.deleteButton {
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-background, #f6f6f6);
  color: var(--light-text-primary, #120029) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  cursor: pointer;
  line-height: normal !important;
}
.cancelButton {
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  background: transparent;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: var(--light-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}
.cancelButton:hover {
  background: transparent;
}
.deleteButton:hover {
  background: var(--neutrals-background, #f6f6f6);
}
