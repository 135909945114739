.basicInfo {
  height: 55%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.workInfo {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 35px;
  background-color: #fafafa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.avatar {
  height: 64px !important;
  width: 64px !important;
  background-color: #eeedf7 !important;
  color: #3b358a !important;
  border: 0.5px dashed #807bc6;
  font-size: 20px;
  font-weight: 700;
}

.name {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #201f28;
}

.email {
  font-size: 14px;
  font-weight: 500;
  color: #636269;
  font-family: "Satoshi-Variable";
}

.speciality {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 32px;
}

.iconWithLabel {
  width: 160px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Satoshi-Variable";
  color: #77767c;
}

.value {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.location {
  font-size: 13px;
  font-weight: 500;
  font-family: "Satoshi-Variable";
  color: #77767c;
  text-align: left;
}

.greeting {
  height: 82px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 32px;
}

.statsContainer {
  height: 90px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 32px;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  
}
