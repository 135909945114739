.loadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.bar {
  background: linear-gradient(102.29deg, #3b358a 0%, #928dd9 100%);
}

.redirectText {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Satoshi-Variable" !important;
  opacity: 1 !important;
  color: white;
}
