.mainContainer {
  display: flex;
  width: 22%;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(243, 240, 253, 0.06);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.title {
  color: #fff !important;
  text-align: center !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 140% */
}
