.container {
  padding: 0px !important;
  background-color: #ffffff;
box-shadow: none;
  border-radius: 4px !important;
  height: 100%;
}

.greeting {
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  font-family: "Red Hat Display" !important;
}

.chip {
  background-color: #f6f6f6;
  padding: 1px 6px 1px 6px;
  border-radius: 30px;
  color: #636269;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px !important;
  height: 26px !important;
}

.titleFlex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  gap: 8px;
}

.arrowFlex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  gap: 24px;
}

.arrowIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.feedbackCardContainer {
  display: flex;
  padding: 32px;
  gap: 24px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.arrowIconDisabled {
  color: #a6a5a9 !important;
}
