.mainContainer {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  /* height: 100px; */
}
.flexRow {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}
.leftSideBar {
  width: 25%;
  padding: 20px;
  height: 100vh;
}
.mainPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    190deg,
    rgba(255, 255, 255, 0.2) -99.49%,
    rgba(255, 255, 255, 0) 80.41%
  );
  /* padding: 20px 20px 0px 20px; */
  width: 75%;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.quizContainer {
  display: flex;
  width: 65%;
  height: 366px;
  padding: 20px;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(
    190deg,
    rgba(255, 255, 255, 0.2) -99.49%,
    rgba(255, 255, 255, 0) 80.41%
  );
}
.quizHeading {
  color: #fff !important;
  width: 80%;
  display: flex;
  text-align: left !important;
  flex-wrap: wrap;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  /* line-height: 40px !important; 125% */
}
.moduleTitle {
  color: var(--light-primary-bg, #9747ff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 1.4px !important;
  text-transform: uppercase !important;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: pink; */
  align-items: center;
}
.criteriaText {
  color: rgba(255, 255, 255, 0.5) !important;
  /* width: 20%; */
  /* H5/medium */
  margin: auto 0;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 24px !important; /* 150% */
}
.criteriaTextOp {
  color: rgba(255, 255, 255, 0.8) !important;
  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.subtitle {
  color: #fff !important;

  /* H5/medium */
  font-size: 16px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.description {
  color: #fff !important;
  opacity: 0.5 !important;
  /* H5/regular */
  font-size: 16px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
}
