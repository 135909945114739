.mainContainer {
  display: flex;
  margin-top: 10px;
  padding: 4px 8px 4px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.badgeScore {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}
.flexRow {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: start;
}
.badgeTitle {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
