.container {
  padding: 0px !important;
  background-color: none;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.backButton {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1vw;
}

.secondaryContainer {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  padding: 0px !important;
  margin-top: 50px;
}

.analyticsBox {
  height: 346px;
  width: 684px;
}

.userInfoBox {
  height: 346px;
  width: 480px;
  background-color: #fff;
  border-radius: 4px;
box-shadow: none;
}

.deleteProspect {
  background-color: #faeded !important;
  color: #a33939 !important;
  width: 162px !important;
  height: 40px !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.deleteProspect:hover {
  background-color: #faeded !important;
}

.deleteProspect img {
  margin-top: 0px;
}

.tableContainer {
  padding: 0px !important;
  margin-top: 50px;
}

@media only screen and (max-width: 1200px) and (min-width: 1065px) {
  .goodMorningText {
    font-size: 20px !important;
  }

  .upcomingContainer {
    padding-bottom: 5px !important;
  }

  .upcomingText {
    font-size: 18px !important;
  }

  .moreMeeting {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    flex-wrap: wrap;
  }

  .userInfoBox {
    height: 100%;
    width: 82vw;
  }

  .analyticsBox {
    width: 82vw;
    margin-top: 5vh;
  }

  .tableContainer {
    width: 90% !important;
    margin-left: 0px !important;
  }

  .firstTd {
    font-family: "Satoshi-Variable" !important;
    color: #201f28 !important;
    transform: translate(-70px, 0px) !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    flex-wrap: wrap;
  }

  .userInfoBox {
    height: 45vh;
    width: 90vw;
  }

  .analyticsBox {
    width: 90vw;
    margin-top: 5vh;
  }

  .tableContainer {
    width: 90% !important;
    margin-left: 0px !important;
  }

  .firstTd {
    font-family: "Satoshi-Variable" !important;
    color: #201f28 !important;
    transform: translate(-20px, 0px) !important;
  }
}
