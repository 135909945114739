.greeting {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.chipDefault {
  background-color: #f6f6f6 !important;
  color: #636269 !important;
  border: none !important;
  width: fit-content !important;
  height: 26px !important;
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 400 !important;
  padding: 4px 8px 4px 8px !important;
  border-radius: 30px !important;
}

.chipContainer {
  width: 80%;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 24px 32px;
  scroll-behavior: smooth;
}

.chipDefaultSelected {
  background-color: #4a42ad !important;
  color: #ffffff !important;
  border: none !important;
  width: fit-content !important;
  height: 26px !important;
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 400 !important;
  padding: 4px 8px 4px 8px !important;
  border-radius: 30px !important;
}

.feedbackTimeChip {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 25px;
}

.arrowIconContainer {
  cursor: pointer;
}

.chartContainer {
  width: 37%;
  margin-top: 2vh;
}

.statContainer {
  width: 87%;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 2vh;
}

.explanation {
  margin-top: 2vh;
  width: 87%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.title {
  text-align: left;
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Red Hat Display" !important;
  color: #201f28 !important;
}

.desc {
  text-align: left;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Satoshi-Variable" !important;
  color: #77767c !important;
}

.approveButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px !important;
}

.approveContainer {
  margin-top: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 4px;
  width: 90%;
  padding: 32px;
  gap: 16px;
}

.approvePrompt {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Red Hat Display" !important;
  color: #201f28 !important;
}

.agreeButton {
  width: 192px !important;
  height: 40px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.disagreeButton {
  width: 192px !important;
  height: 40px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.agreeButton {
  background-color: #ecf7f0 !important;
  border-radius: 4px !important;
  padding: 9px 16px 9px 16px !important;
  gap: 8px !important;
  text-transform: none !important;
  color: #328e51 !important;
}

.disagreeButton {
  background-color: #faeded !important;
  border-radius: 4px !important;
  padding: 9px 16px 9px 16px !important;
  gap: 8px !important;
  text-transform: none !important;
  color: #a33939 !important;
}
