.container {
  width: 100%;
  height: 100%;
  display: flex;
}
.leftContainer {
  width: 25%;
  padding: 20px;
}
.rightContainer {
  width: 50%;
  padding: 40px;
  height: 100%;
  background: linear-gradient(
    119deg,
    #151515 -176.78%,
    rgba(73, 73, 73, 0) 62.32%
  );
}
.topLeftContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
}
.leftEmptyProductsContainer {
  display: flex;
  height: 261px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
}
.sectionHeader {
  color: var(--Neutral-1, #fff) !important;

  /* H4/Bold */
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 140% */
}
.emptyInfoText {
  color: var(--Neutral-1, #fff) !important;
  text-align: center !important;
  width: 80%;
  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}

.addProduct {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #fff;
  background-color: transparent;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--Neutrals-Primary_white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.MenuListContainer {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  margin-top: 5px;
}
.MenuListContainerActive {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
}
.MenuItem {
  color: var(--neutral-1, #fff) !important;

  /* H5/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.rightSectionHeader {
  color: var(--Neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.settingsButton {
  display: flex;
  height: 48px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--Neutrals-Primary_white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  border: none;
  line-height: normal !important;
  cursor: pointer;
}
.assignedButton {
  display: flex;
    height: 30px;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
  border: 1px solid #fff;
  color: var(--light-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}
.assignButton {
  display: flex;
  height: 30px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--dark-inverted-bg-8, #551ba0);

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--Neutrals-Primary_white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  border: none;
  line-height: normal !important;
  cursor: pointer;
}
.uploadButton {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 12px;
  background: #551ba0;

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}
.uploadButton:hover {
  background: #551ba0;
}
.documentsHeader {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  align-self: flex-start;
  /* Captiol Heading 50 */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.searchContainer {
  display: flex;
  gap: 10px;
  width: inherit;
  align-items: center;
}
.documentsContainer {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  gap: 4vh;
}
.usersListContainer {
  border-radius: 8px;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.25);
}
.userList {
  padding: 14px 16px;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardListContainer {
  height: 100%;
  padding: 35px 0px 0px 0px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.headerText {
  color: white !important;
}
.settingsButton:hover {
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.assignButton:hover {
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.rightEmptyContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
}
