.basicInfo {
  height: 55%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* gap: 8px; */
}

.workInfo {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 35px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.avatar {
  height: 64px !important;
  width: 64px !important;
  background-color: #eeedf7 !important;
  color: #3b358a !important;
  border: 0.5px dashed #807bc6;
  font-size: 20px;
  font-weight: 700;
}

.name {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #201f28;
}

.email {
  font-size: 14px;
  font-weight: 500;
  color: #636269;
  font-family: "Satoshi-Variable";
}

.speciality {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 32px;
}

.iconWithLabel {
  width: 160px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Satoshi-Variable";
  color: #77767c;
}

.value {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.location {
  font-size: 13px;
  font-weight: 500;
  font-family: "Satoshi-Variable";
  color: #77767c;
}
.greetingText {
  color: var(--light-white, #fff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: -0.4px !important;
}
.greeting {
  width: 100%;
  display: flex;
  opacity: 0.8;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
}

.newTask {
  padding-left: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: left;
  align-items: left;
  text-align: left;
  cursor: pointer;
  color: #3b358a;
  font-family: "Satoshi-Variable";
}

.newTaskContainer * {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  gap: 5px;
}

.workInfo .p {
  display: none !important;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

input {
  border: none;
  outline: none;
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-size: 500;
}

input::placeholder {
  color: #a6a5a9;
}

.searchIcon {
  height: 16px !important;
  width: 16px !important;
  color: #77767c !important;
}

.chipDefault {
  background-color: #f6f6f6 !important;
  color: #636269 !important;
  border: none !important;
  width: fit-content !important;
  height: 26px !important;
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 400 !important;
  padding: 4px 8px 4px 8px !important;
  border-radius: 30px !important;
}

.chipDefaultSelected {
  background-color: #4a42ad !important;
  color: #ffffff !important;
  border: none !important;
  width: fit-content !important;
  height: 26px !important;
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 400 !important;
  padding: 4px 8px 4px 8px !important;
  border-radius: 30px !important;
}

.chipContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 24px 32px;
}

.transcriptedTextContainer {
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  padding: 24px 32px 25vh 32px;
  /* background: linear-gradient(
    180deg,
    #141315 -120%,
    #15141600 50%,
    #15141600 120%
  ); */

  overflow-y: scroll;
}
