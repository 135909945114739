.itemSelected {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
  width: 160px !important;
  height: 46px !important;
  padding: 12px 16px 12px 16px !important;
  /* background-color: #fafafa !important; */
}
.filterText {
  color: var(--neutrals-muted, #77767c) !important;

  /* H5/bold */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.itemNotSelected {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
  width: 160px !important;
  height: 46px !important;
  padding: 12px 16px 12px 16px !important;
  /* background-color: #ffffff !important; */
}

/* .itemNotSelected:hover {
  background-color: #fafafa !important;
} */

.optionNotSelected {
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  /* color: #636269 !important; */
  padding-left: 8px !important;
}

.optionNotSelected:hover {
  background-color: inherit !important;
  /* color: #201f28 !important; */
}

.optionSelected {
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  /* color: #201f28 !important; */
  padding-left: 8px !important;
}

.optionSelected:hover {
  background-color: inherit !important;
}

.MuiList-root-MuiMenu-list {
  width: 500px !important;
}
