.container {
  height: 100%;
  padding-bottom: 150px;
}
.innerMainContainer {
  width: 98%;
  margin: auto;
  padding-top: 20px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.innerContainer {
  background: linear-gradient(
    144deg,
    #9747ff -29.36%,
    rgba(100, 0, 229, 0.27) 1.46%,
    rgba(100, 0, 229, 0) 31.03%
  );
  height: 100%;
}
.dashboardContainer {
  display: flex;
  align-items: flex-start;
  height: 100%;
  /* align-self: stretch; */
  border-radius: 20px;
  /* max-height: fit-content; */
  /* padding: 40px; */
  background: #030204;
  margin-top: 30px;
}
