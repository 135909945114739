.container {
    margin: 0px;
    display: flex;
    width: 100%;
  }
  .wrapper1 {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding-top: 3vh;
    padding-right: 20px;
    padding-left: 20px;
    gap: 3vh;
  }
.MenuListContainer {
    display: flex;
    padding: 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
  }
  .MenuListContainerActive {
    display: flex;
    padding: 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.08);
  }
  .MenuItem {
    color: var(--neutral-1, #fff) !important;
  
    /* H5/bold */
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important; /* 150% */
  }
  .settingSection {
    width: 75%;
    height: 100%;
    padding: 32px;
    background: linear-gradient(
      90deg,
      #151515 -122.7%,
      rgba(73, 73, 73, 0) 45.67%
    );
  }

  .settingsGrid {
    padding: 20px 20px 0px 0px !important;
  }
  .organisationContainer {
    display: flex;
    width: 70%;
    padding: 12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  }
  .organizationInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .organizationName {
    color: rgba(255, 255, 255, 0.8) !important;
  text-align: left !important;
    /* Body/bold */
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important; /* 128.571% */
  }
  .subtitle {
    color: rgba(255, 255, 255, 0.5) !important;
  
    /* Footnote/description */
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important; /* 166.667% */
  }
  .submitButton {
    height: 40px !important;
    width: 127px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: "Satoshi-Variable" !important;
    color: #fff !important;
    background-color: #4a42ad !important;
    text-transform: none !important;
  }
  .editButton {
    height: 40px !important;
    width: 127px !important;
    color: var(--neutrals-primary-white, #fff) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    border-radius: 4px;
    background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;
  
    /* Button_shadow */
    box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
    text-transform: none !important;
    outline: none;
  }

  .dateSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    gap: 10px;
    height: 40px !important;
    width: 200px !important;
    color: var(--neutrals-primary-white, #fff) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    border-radius: 4px;
    background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;
  
    /* Button_shadow */
    box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
    text-transform: none !important;

  }

  .headingText {
    color: var(--neutral-1, #fff) !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px !important; /* 116.667% */
    letter-spacing: -0.48px !important;
  }
  .submitButtonDisabled {
    height: 40px !important;
    color: var(--neutrals-primary-white, #fff) !important;
    font-size: 14px !important;
    width: 127px !important;
    opacity: 0.4;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    border-radius: 4px;
    background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;
  
    /* Button_shadow */
    box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
    text-transform: none !important;
  }
  .gridItem {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px;
  }
  
  
  .item {
    height: 74px;
  }
  .textarea {
    height: 200px;
  }
  .itemContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 74px;
  }
  
  .buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .submitButton {
    height: 40px !important;
    width: 127px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: "Satoshi-Variable" !important;
    color: #fff !important;
    background-color: #4a42ad !important;
    text-transform: none !important;
  }
  
  @media only screen and (max-width: 1065px) and (min-width: 876px) {
    .titleContainer {
      justify-content: flex-start;
      gap: 20px !important;
    }
  
    .secondaryContainer {
      width: 92% !important;
      padding-left: 0px !important;
      margin-left: 0px !important;
    }
  
    .settingsGrid {
      /* flex-direction: column !important; */
      display: flex;
      gap: 20px;
      padding-top: 0px !important;
      padding-left: 10px !important;
    }
  
    .gridItem {
      display: flex !important;
      flex-direction: column !important;
      gap: 8px;
      padding-top: 12px !important;
    }
  
    .buttonContainer {
      width: 124px;
    }
  }
  
  @media only screen and (max-width: 876px) and (min-width: 720px) {
    .titleContainer {
      justify-content: flex-start;
      gap: 20px !important;
    }
  
    .secondaryContainer {
      width: 92% !important;
      padding-left: 0px !important;
      margin-left: 0px !important;
    }
  
    .settingsGrid {
      padding: 20px !important;
      flex-direction: column !important;
      gap: 20px;
      padding-top: 0px !important;
      padding-left: 10px !important;
    }
  
    .gridItem {
      display: flex !important;
      flex-direction: column !important;
      gap: 8px;
      padding-top: 12px !important;
    }
  
    .buttonContainer {
      width: 124px;
    }
  }
  