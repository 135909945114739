/* YourStyles.module.css */
.productContainer {
  display: flex;
  height: 20vh;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.documentContainer {
  display: flex;
  /* height: 20vh; */
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.documentHeading {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  justify-content: space-between;
}
.emptyButton {
  margin-top: 15px;
  display: flex !important;
  color: #fff !important;
  /* height: 48px !important; */
  padding: 13px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 4px !important;
  border: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12) !important;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set container height to 100% of viewport height */
}
.emptyDescription {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 150% */
  letter-spacing: 0.25px !important;
}

.emptyContainer {
  display: flex;
  width: 70% !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* flex: 1 0 0; */
}
.emptyGridContainer {
  display: flex;
  width: 70% !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 50px;
}
.emptyHeading {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 171.429% */
  letter-spacing: 0.25px !important;
}

.tabContent {
  /* width: 100%; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex: 1; /* Allow tab content to expand and take available space */
  /* overflow-y: scroll; Enable vertical scrolling for tab content */
  overflow-x: hidden;
  /* height: fit-content; */
  margin-bottom: 30px;
  /* max-height: fit-content; */
  padding: 16px; /* Add padding for better spacing */
}

.title {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Captiol Heading 50 */
  font-family: Satoshi Variable;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
@media screen and (max-width: 1230px) {
  .productContainer {
    height: 17vh;
  }
}
