.container {
  display: flex;
  gap: 1vw;
  width: 100%;
}
.leftContainer {
  display: flex;
  width: 25%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.2vh;
  align-self: stretch;
}
.rightContainer {
  display: flex;

  width: 75%;
  padding: 2vh 0px 0px 1vw;
  border-right: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
}
.meetingSearchContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
  background: linear-gradient(
    133deg,
    #51ffe0 -86.89%,
    rgba(114, 128, 179, 0.28) -20.19%,
    rgba(97, 189, 255, 0) 43.79%
  );
}
.searchTitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.searchBarContainer {
  display: flex;
  padding: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.flexRow {
  display: flex;
  gap: 8px;
  width: 100%;
}
.searchInput {
  width: 100%;
  background: transparent;
  padding: 4px;
  color: #c3c2c5 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  /* Elevations/E2 */
}
.scheduleButton {
  display: flex;
  padding: 13px 20px 13px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background: #551ba0;

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.searchInput::placeholder {
  color: #c3c2c5 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.scheduleButton:hover {
  background: #551ba0;
}
.tabContainer {
  display: flex;
  padding: 12px 20px 0px 20px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.tab {
  display: flex;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  /* border-bottom: 2px solid var(--dark-inverted-bg-100, #fff); */
}
.tabText {
  padding-right: 4px;
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.countContainer {
  display: flex;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24));
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 0.11px !important;
  text-transform: uppercase !important;
}
