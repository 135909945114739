.verificationContainer {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff !important;
  display: flex !important;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 64px;
}

.integrationContainer {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 85%;
  width: 80%;
  gap: 64px;
  padding: 0px !important;
}

.textContainer {
  width: 82%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.headingText {
  color: #201f28 !important;
  font-family: "Red Hat Display" !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.leftText {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.header {
  width: 85%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 3vh !important;
  z-index: 1;
  padding-left: 0px;
  padding-right: 0px;
}

.headerButton {
  background-color: #e9e9e9 !important;
  text-transform: none !important;
  color: #201f28 !important;
  border-radius: 4px;
}

.subText {
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #77767c !important;
}

.headerButtonContainer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
}

a {
  color: #3b358a;
  font-size: 16px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.divider {
  width: 82%;
  color: #e9e9e9 !important;
}

.cardContainer {
  width: 80%;
  padding: 0px !important;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .integrationContainer {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 85%;
    width: 80%;
    gap: 48px;
    padding: 0px !important;
  }

  .cardContainer {
    width: 90% !important;
    padding: 0px !important;
    overflow-y: scroll !important;
    padding: 0px !important;
    overflow-x: hidden;
  }

  a {
    font-size: 12px !important;
  }

  .header {
    width: 90%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 3vh !important;
    z-index: 1;
    padding-left: 0px;
    padding-right: 0px;
  }

  .textContainer {
    width: 90%;
  }

  .divider {
    width: 90%;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .integrationContainer {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 85%;
    width: 80%;
    gap: 48px;
    padding: 0px !important;
  }

  .cardContainer {
    width: 75% !important;
    padding: 0px !important;
    overflow-y: scroll !important;
    padding: 0px !important;
    overflow-x: hidden;
  }

  a {
    font-size: 12px !important;
  }

  .header {
    width: 90%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 3vh !important;
    z-index: 1;
    padding-left: 0px;
    padding-right: 0px;
  }

  .textContainer {
    width: 90%;
  }

  .divider {
    width: 90%;
  }

  .leftText {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    text-align: left;
    width: 75%;
  }

}
