.graphValue {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  /* font-size: 24px !important; */
  position: absolute;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 24px !important; /* 100% */
  letter-spacing: 0.25px !important;
}

@media (min-width: 1023px) and (max-width: 1230px) {
  .graphValue {
    font-size: 16px !important;
  }
}
