.container {
  background: black;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
}
.innerContainer {
  width: 100%;
  background: #141415;
}
.menuItem {
  color: #fff;
  text-align: center;

  /* H5/medium */
  /* font-family: Roboto !important !important; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.flexRow {
  display: flex;
  align-items: center;
  gap: 22px;
  /* background-color: pink; */
  justify-content: flex-start;
  width: 50%;
}
.flexEnd {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 22px;
}
