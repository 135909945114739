@import url("./Fonts//satoshi.css");

.App {
  text-align: center;
  background: var(--dark-surface-1, #141415) !important;
  font-family: "Red Hat Display" !important;
  min-height: fit-content;
}

html {
  background: var(--dark-surface-1, #141415) !important;
}
/* button[title="Leave"] {
 
  display: none;
} */
.ql-container {
  display: flex;
  flex-direction: column-reverse;
}
.recharts-default-legend {
  display: none;
}
.arrow {
  position: absolute;
  top: 50%;
}

.right {
  right: 0;
}

.left {
  left: 0;
}
.ql-editor {
  max-height: 250px !important;
  min-height: 200px !important;
  overflow-y: scroll !important;
}
.custom-needle-css{
  transition: transform 0.8s ease;
}
.ql-toolbar.ql-snow {
  text-align: left;
}
.ql-snow .ql-stroke {
  stroke: white;
}
.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  stroke: white;
}
.zmwebsdk-makeStyles-participantsCustomize-2.zmwebsdk-makeStyles-participantsCustomize-9 {
  transform: translate3d(20px, 50px, 0px) !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #e5e5e5;
}

* {
  font-family: "Red Hat Display";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
