.attachedDocument {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 24px;
  margin-top: 5vh;
  height: 126px;
  background-color: #fff5ed;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.name {
  text-align: left;
  font-family: "Red Hat Display" !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #cc7639 !important;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #ff974c;
  height: 62px;
  width: 62px;
}

.description {
  text-align: left;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  width: fit-content;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #636269 !important;
}

.unverifiedDocument {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 16px !important;
}

.closeIcon {
  color: #ff974c;
  height: 20px !important;
  width: 20px !important;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
    .attachedDocument {
        width: 90%;
      }
  }
  
  @media only screen and (max-width: 876px) and (min-width: 720px) {
    .attachedDocument {
        width: 90%;
      }
  }
  