.MuiTabsRoot div {
  gap: 1vw;
}

.tabContainer {
  width: 100% !important;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .tabContainer {
    width: 92% !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .tabContainer {
    width: 92% !important;
  }
}
