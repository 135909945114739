

.subtext {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px !important;
  flex-direction: row;
  gap: 4px;
}

.textBox {
  display: "flex" !important;
  flex-direction: "column" !important;
  text-align: "center" !important;
  justify-content: "center" !important;
  margin-left: 8px;
}

.textBox * {
  text-align: center;
}
