.container {
  display: flex;
  justify-content: center;
  border-radius: var(--unit-unit-3, 12px);
  border: 3px solid rgba(255, 255, 255, 0.24);
  background: var(--theme-dark-0, #222224);
  width: 100%;
  flex-shrink: 0;
  outline: none;
  color: var(--light-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.innerContainer {
  display: flex;
  align-items: center;
  width: 90%;
}
.inputField {
  background: var(--theme-dark-0, #222224);
  width: 100%;
  padding: 13px 22px;
  color: var(--light-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.inputField::placeholder {
  color: var(--neutrals-muted, #77767c) !important;

  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
