.container {
  padding: 0vh 0vh 0vh 0vh !important;
  background-color: none;
  height: 100vh;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #201f28;
}

.secondaryContainer {
  width: 100%;
  padding: 0px !important;
  margin-top: 30px;
  border-radius: 4px;
}

.libraryContainer {
  width: 100%;
  padding: 0px;
  box-shadow: none;
  background-color: white;
  border-radius: 4px;
  box-shadow: none;
  padding: 0px !important;
}

.libraryFlex {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  padding: 0px !important;
}

.menuSection {
  height: 600px;
  width: 23%;
  border-right: 1px solid #e9e9e9;
  padding: 0px !important;
}

.searchFieldContainer {
  width: 100%;
  padding: 32px 16px 16px 32px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  height: 54px;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
}

.listItem p {
  margin-left: 12px;
  color: #77767c;
  font-family: "Satoshi-Variable";
  font-weight: 500;
  font-size: 16px;
}

.listItemSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  height: 54px;
  width: 100%;
  background-color: #fafafa;
  border-right: 3px solid #807bc6;
  cursor: pointer;
}

.listItemSelected p {
  margin-left: 12px;
  color: #201f28;
  font-family: "Satoshi-Variable";
  font-weight: 500;
  font-size: 16px;
}

.cardSection {
  width: 77%;
  height: 600px;
  padding: 32px;
}

.cardListContainer {
  height: 96%;
  padding: 35px 0px 10px 10px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
