.container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none !important;
  background: var(--light-primary-bg, #9747ff);
  box-shadow: 0px 8px 40px 0px rgba(83, 58, 238, 0.08);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}
.secondaryContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none !important;

  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  box-shadow: 0px 8px 40px 0px rgba(83, 58, 238, 0.08);
  color: var(--light-white, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  cursor: pointer;
}
.container:hover {
  background: var(--light-primary-bg, #9747ff);
}
