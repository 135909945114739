.container {
  width: 100%;
  display: flex;
  height: 100%;
}
.leftContainer {
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;
  align-self: stretch;
}
.rightContainer {
  display: flex;
  width: 75%;
  height: 100%;

  padding: 40px 20px 0px 20px;
  flex-direction: column;
  background: linear-gradient(
    119deg,
    #151515 -176.78%,
    rgba(73, 73, 73, 0) 62.32%
  );
  align-items: flex-start;
  gap: 40px;
}

.headerContainer {
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.deleteButton {
  cursor: pointer;
  display: flex;
  padding: 13px 20px;
  background: transparent;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dark-red-0, #e51a3f);
  color: var(--dark-red-0, #e51a3f) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.headerText {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.uploadButton {
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--neutrals-primary-white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer;
}
.uploadButton:hover {
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.searchContainer {
  display: flex;
  gap: 10px;
  width: inherit;
  align-items: center;
}
.filterText {
  color: var(--neutrals-muted, #77767c) !important;

  /* H5/bold */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.cardListContainer {
  height: 100%;
  padding: 35px 0px 0px 0px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
