.container {
  padding: 0px 0px 0px 0px !important;
  background-color: #ffffff;
  margin-top: 3vh;
box-shadow: none;
  border-radius: 4px !important;
  height: 90%;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  text-align: start;
  padding-left: 15px;
}

.greetingSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vw;
  background: linear-gradient(130deg, #4a42ad 0% 16%, white 15% 80%);
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
.greeting {
  padding-left: 10px;
  gap: 4px;
}

.avatar {
  height: 48px !important;
  width: 48px !important;
  border: 2px solid #4a42ad;
}

.avatar img {
  height: fit-content !important;
  width: fit-content !important;
  margin-top: 0px !important;
}

.btn {
  width: 100%;
  border: 1.5px solid #e9e9e9 !important;
  color: #77767c !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Satoshi-Variable' !important;
}

.btn:hover {
  background-color: none;
}

.btnSelected {
  width: 100%;
  background-color: #eeedf7 !important;
  border: 1.5px solid #e9e9e9 !important;
  color: #3b358a !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Satoshi-Variable' !important;
}

.statsContainer {
  display: flex;
}

.graphSection {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 25px;
}

.graphSectionTextContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.graphSectionTextBox {
  display: flex;
  flex-direction: row;
}

.graphContainer {
  padding: 10px;
  width: 70%;
}

.graph {
  width: 25%;
}

.tip {
  width: 100%;
  margin-top: 10px;
  padding-top: 25px;
  padding-left: 0px !important;
  display: flex;
  align-items: flex-start;
}

.tipText {
  color:'#77767C';
  padding-left: 0px;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {

  .greetingSection {
    
    background: linear-gradient(130deg, #4a42ad 0% 12%, white 12% 80%);
    
  }

  
}

@media only screen and (max-width: 876px) and (min-width: 720px) {

  .greetingSection {
    
    background: linear-gradient(130deg, #4a42ad 0% 12%, white 12% 80%);
    
  }
  
}
