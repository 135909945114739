.description {
  color: var(--golden-purple-1, #f9f0ff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left;
  /* Captiol Heading 50 */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.courses {
  color: var(--on-surface-subdued-d, rgba(255, 255, 255, 0.48)) !important;

  /* H5/regular */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
}
.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  margin-left: 15px;
}
.title {
  color: #fff !important;
  text-align: left;

  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
}
.flexRow {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
