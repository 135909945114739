.dialogueTitle {
  font-family: "Red Hat Display" !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px;
  color: #ffffff !important;
}
input:disabled {
  color: #bcbcbf !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

input {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

.inputContainer {
  box-shadow: none !important;
  border-bottom: 2px solid #eeedf7 !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.inputContainer:has(input:focus) {
  border-bottom: 2px solid #807bc6 !important;
}

.input::-webkit-input-placeholder {
  display: none !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.menuItem {
  height: 46px;
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  color: #636269 !important;
  font-weight: 500 !important;
}

.menuItem:hover {
  height: 46px;
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #201f28 !important;
  background-color: transparent !important;
}

.menuItemContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px 16px 0px 0px;
  border-radius: 4px;
}

.menuItemContainer:hover {
  background-color: #fafafa;
}

.addText {
  display: none !important;
}

.menuItemContainer:hover .addText {
  display: block !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  color: #3b358a !important;
  font-size: 14px !important;
  cursor: pointer;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .inputContainer {
    width: 350px !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .inputContainer {
    width: 350px !important;
  }
}

.dialogue {
  min-width: 700px !important;
}
.scheduleMeetingFormContainer {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px 4px !important;
  gap: 16px !important;
}

.formRow {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 0px 0px !important;
  gap: 20px;
}

.lightText {
  font-family: "Satoshi-Variable" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #77767c !important;
}

.addDescription {
  background-color: #fafafa;
  height: 30px;
  width: 143px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px;
  padding: 4px 8px 4px 8px !important;
  border-radius: 4px;
}

.addDescriptionText {
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #77767c !important;
}

.addIcon {
  color: #77767c !important;
  height: 16px;
  width: 16px;
}

.fieldIcon {
  height: 20px;
  width: 20px;
  color: #77767c;
}

.spacerRow {
  margin-top: 2vh;
  width: 100%;
}

.spacerTitle {
  font-family: "Red Hat Display" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #201f28 !important;
  text-align: left;
}

.cancelButton {
  height: 40px !important;
  width: 77px !important;
  background-color: #f6f6f6 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #201f28 !important;
  text-transform: none !important;
}

.scheduleButton {
  height: 40px !important;
  width: 147px !important;
  background-color: #464092 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  text-transform: none !important;
}
