.container {
  height: 100%;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.leftContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;
  width: 75%;
  border-right: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  min-height: 100%;
  overflow-y: scroll;
  text-align: left;
}
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.emptyTopClients {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dateContainer {
  border-radius: 5px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  padding: 8px;
  margin-right: 8px;
  
}
.statGraphContainer {
  display: flex;
  margin: auto 0;
  padding: 12px;
  width: 25%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.statGraphHeading {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  width: 100%;

  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.statGraphValue {
  color: #fff !important;
  width: max-content;
  /* H2/medium */
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important; /* 133.333% */
}
.statGraphSubtitle {
  color: rgba(255, 255, 255, 0.8) !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
.graphFooter {
  margin-top: 1vh;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 12px;
  align-self: stretch;
  flex-wrap: wrap;
  border-top: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  background: linear-gradient(180deg, #171718 0%, rgba(23, 23, 24, 0) 100%);
}
.footerText {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden !important;
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-overflow: ellipsis !important;
  font-size: 8px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 175% */
  letter-spacing: 0.8px !important;
  text-transform: uppercase !important;
}
.tab {
  display: flex;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  /* border-bottom: 2px solid var(--dark-inverted-bg-100, #fff); */
}
.tabText {
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.tabContainer {
  display: flex;
  padding: 12px 20px 0px 0px;
  margin-top: 3vh;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.dataTableContainer {
  margin-top: 3vh;
}
.suggestionBarContainerVertical {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  max-width: 100%;
  overflow-y: scroll !important;
  gap: 10px;
  align-items: center;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important; /* IE and Edge */
}
.suggestionBarContainer {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll !important;
  gap: 10px;
  align-items: center;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important; /* IE and Edge */
}
.suggestionBarContainer::-webkit-scrollbar {
  display: none !important;
}
.barGraphHeading {
  color: #fff !important;

  /* H4/medium */
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 140% */
}
.rightContainer {
  padding: 20px 20px 40px 20px;

  min-height: 100%; /* try 80vh */
  width: 40%;
  text-align: left;
  overflow-y: auto;
}
.leaderBoardHeader {
  margin-top: 2vh;
  border-bottom: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  background: var(--light-inverted-bg-24, rgba(20, 20, 21, 0.24));
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.headerText {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 160% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.leaderBoard {
  width: 100%;
}
.topClients {
  width: 100%;
}
.topRightContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 3vh;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
}
.sectionHeaderContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
}
.statisticsContainer {
  display: flex;
  padding: 20px 20px 20px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
}
.statisticsInnerContainer {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  align-self: stretch;
}
.sectionHeader {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.dateText {
  color: rgba(255, 255, 255, 0.8) !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
.dateSubtitle {
  color: rgba(255, 255, 255, 0.5) !important;

  /* Footnote/description */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 166.667% */
}
.valueText {
  color: #fff !important;

  /* H4/Bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 140% */
}
.stats {
  display: flex;
  width: 23%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.stats > h3 {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.statsValue {
  color: #fff !important;

  /* H2/medium */
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important; /* 133.333% */
}
@media (min-width: 1650px) {
  .valueText {
    font-size: 18px !important;
  }
}
@media (min-width: 1520px) and (max-width: 1649px) {
  .valueText {
    font-size: 16px !important;
  }
}
