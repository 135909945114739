.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.statisticsContainer {
  display: flex;
  padding: 20px;

  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px 12px 0px 12px;
  background: linear-gradient(
    137deg,
    #51ffe0 -92.8%,
    rgba(87, 223, 174, 0.28) -8.99%,
    rgba(97, 189, 255, 0) 71.39%
  );
}
.statisticsTitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.infoBoxContianer {
  display: flex;
  gap: 10px;
}
.dataTableContainer {
  width: 100% !important;
  padding: 0px !important;
}
