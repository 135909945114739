.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
}
.moduleTitle {
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
}
.moduleRunTimeText {
  color: var(--dark-inverted-bg-100, #fff) !important;
  text-align: right !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important; /* 157.143% */
}
.flexRow {
  display: flex;
  opacity: 0.6 !important;
  justify-content: space-around;
  margin-top: 20px;
  align-items: center;
  width: 90%;
}
.title {
  width: 70%;
  text-align: left !important;
  color: var(--neutral-1, #ffffff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 1.4px !important;
  text-transform: uppercase !important;
}
.flexBetween {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
