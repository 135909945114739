.newDocumentCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 279;
  background-color: #fafafa;
  box-shadow: none;
  height: 160px;
  color: #77767c;
}

.gridItemPage {
  padding: 0px !important;
  margin-top: 15px !important;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px !important;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .gridItemPage {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .gridItemPage {
    grid-template-columns: repeat(1, 1fr);
  }
}
