.container {
  width: 100%;
}
.dividerText {
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 500;
  transform: rotate(270deg);
  color: #636269;
  letter-spacing: 0.0075em;
}
.recordingAnalysisButton {
  background-color: #551ba0 !important;
  /* height: 50px !important; */
  /* margin-bottom: "20px" !important; */
  /* width: 100% !important; */
  color: white !important;
  text-transform: none;
  padding: 9px 16px 9px 16px !important;
  box-shadow: none;
}
.scheduleMeetingButton {
  background-color: #551ba0 !important;
  height: 50px !important;
  /* margin-bottom: "20px" !important; */
  width: 100% !important;
  color: white !important;
  padding: 9px 16px 9px 16px !important;
  box-shadow: none;
}

.scheduleMeetingButton p {
  font-size: 17px !important;
  font-weight: 600;
}
.editorTitle {
  color: #afadb0 !important;
  font-family: "Red Hat Display";
  font-style: bold !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  /* line-height: 19px; */
  /* margin-bottom: 10px; */
  /* identical to box height */
  text-align: left;

  /* Neutrals/Primary_black */

  color: #201f28;
}
.tabsTitle {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  /* Neutrals/Muted */

  color: #77767c;
}

.tabsTitleActive {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  /* Primary/Normal */

  color: #464092;
}
.searchBar {
  background: #f6f6f6;
}
.myTaskButton {
  background-color: #464092 !important;
  color: white !important;
  width: auto !important;
  height: 40px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.005em;
}
.backButton {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1vw;
}

.myTaskButton:hover {
  background-color: inherit;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: var(--neutral-1, #fff);
  font-family: "Red Hat Display" !important;
}

.secondaryContainer {
  width: 100%;
  padding: 0px !important;
  margin-top: 30px;
  border-radius: 4px;
}
.innerContainer {
  margin-top: 20px;
  width: 100%;
  margin: 0px;
}
.secondInnerContainer {
  display: flex !important;
  margin-top: 20px;
  padding: 0px 0px 30px 0px !important;
  justify-content: space-between;
  flex-direction: row;
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 98% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 98% !important;

    padding-left: 0px !important;
    margin-left: 0px !important;
  }
}
