.emptyContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.text {
    font-family: 'Red Hat Display' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #636269 !important;
}

.sub {
    font-family: 'Satoshi-Variable' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #77767C !important;
}