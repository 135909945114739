.drawer {
  position: fixed;
  top: 0;
  right: -300px; /* Start off-screen */
  width: 300px;
  display: none;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-out;
  background: #030204;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}
.drawerOpen {
  position: absolute;
  top: 0;
  right: 0; /* Start off-screen */
  width: 35%;
  bottom: 0;
  /* height: 88vh; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-out;
  background: #030204;

  display: flex;
  /* padding: 4vh 2vw 0px 0; */
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  z-index: 9999;
  padding: 1vw;
  align-self: stretch;
}
.description {
  height: 48vh;
  overflow-y: auto;
  color: var(--dark-inverted-bg-100, #fff) !important;
  opacity: 0.5;
  /* H5/medium */
  font-size: 16px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
  margin-top: 1vh;
}
.meetingStartTime {
  color: #57f9c8 !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important; /* 123.077% */
  letter-spacing: 1.3px !important;
  text-transform: uppercase !important;
}
.meetingTitle {
  color: #fff !important;
  font-size: 32px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 40px !important; /* 125% */
}
.meetingDetails {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2vh;
  align-self: stretch;
  flex-wrap: wrap;
}
.meetingDuration {
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.meetingDate {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: left !important;

  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}
.meetingInfoContainer {
  /* background-color: pink; */
  width: 100%;
}
.description::-webkit-scrollbar {
  display: none;
}
.bottomContainer {
  display: flex;
  padding: 20px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 1vw;
  align-self: stretch;
  border-top: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
}
.buttonContainer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  cursor: pointer;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
}
.joinMeeting {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-background, #f6f6f6);
  color: var(--light-text-primary, #120029) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  cursor: pointer;
  border: none !important;
  line-height: normal !important;
}
.joinMeeting:hover {
  border-radius: 4px;
  background: var(--neutrals-background, #f6f6f6);
  color: var(--light-text-primary, #120029) !important;
}
