.container {
  padding: 0px !important;
  background-color: none;
  height: 100vh;
  width: 1440px;
}

.container * {
  z-index: 10;
}

.backgroundContainer {
  position: absolute;
  display: block;
  z-index: 0;
  background-color: #4a42ad;
  height: 320px;
  width: 1440px;
}

.bgImage {
  position: absolute;
  left: 55%;
  top: 20px;
}

.myTaskButton {
  background-color: #eeedf7 !important;
  color: #201f28 !important;
  width: 128px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.myTaskButton:hover {
  background-color: inherit;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #ffffff;
  font-family: "Red Hat Display" !important;
}

.secondaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 0px !important;
  margin-top: 60px;
  gap: 10px !important;
}

.cardContainer {
  width: 100%;
  padding: 0px !important;
  margin-top: 50px;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start !important;
    padding-left: 2vw;
    gap: 20px !important;
  }

  .container {
    width: 1065px !important;
  }

  .secondaryContainer {
    padding-left: 2vw !important;
  }

  .backgroundContainer {
    position: absolute;
    display: block;
    z-index: 0;
    background-color: #4a42ad;
    height: 320px;
    width: 1065px;
  }

  .bgImage {
    position: absolute;
    left: 50%;
    top: 20px;
    
  }

  .cardContainer {
    padding-left: 2vw !important;
    flex-wrap: wrap !important;
  }

}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start !important;
    padding-left: 0vw;
    gap: 20px !important;
  }

  .container {
    width: 876px !important;
  }

  .secondaryContainer {
    padding-left: 0vw !important;
  }

  .backgroundContainer {
    position: absolute;
    display: block;
    z-index: 0;
    background-color: #4a42ad;
    height: 320px;
    width: 100%;
  }

  .bgImage {
    position: absolute;
    left: 47%;
    top: 20px;
    transform: scale(0.8);
  }

  .cardContainer {
    padding-left: 0vw !important;
    flex-wrap: wrap !important;
  }
}
