.registrationContainer {
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
/* Default styling for the checkbox */
.checkbox {
  border: 1px solid green !important;
  color: none !important;
}

/* Styling when the checkbox is checked */
.checkbox:checked {
  border: 1px solid purple !important;
}

.createAccount {
  color: rgba(255, 255, 255, 0.8) !important;
  padding-bottom: 20px;
  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
a {
  color: var(--light-primary-bg, #9747ff) !important;

  text-decoration: none; /* Optionally, you can remove underlines */
}

a:hover {
  text-decoration: underline; /* Optionally, add underline on hover */
}
.welcomeTitle {
  color: #fff !important;
  text-align: left !important;
  /* H3/medium */
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important; /* 133.333% */
}
.subTitle {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: left !important;
  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.termsText {
  width: 65%;
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;

  /* Body/Medium_14 */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 22.4px */
  letter-spacing: 0.07px !important;
}
.coverImage {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.loginFormContainer {
  display: flex;
  padding: 40px;
  margin-top: 50px;
  width: 25%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: var(--unit-unit-5, 20px);
  border: 6px solid #00000054;
  background: linear-gradient(
    175deg,
    #5b2e9a -2.59%,
    #030204 11.76%,
    #030204 148.64%
  );
  box-shadow: 0px 90px 90px 0px rgba(0, 0, 0, 0.25);
}
.RememberMeContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.forgotText {
  color: var(--light-primary-bg, #9747ff) !important;
  text-align: right !important;
  font-size: 14px !important;
  font-style: normal !important;
  cursor: pointer;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.07px !important;
}
.RememberMeText {
  color: var(--neutrals-muted, #77767c) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.07px !important;
}
.signUp {
  width: 100%;
  height: 100%;
  padding: 0px !important;
}

.backgroundTemplate {
  position: absolute;
  top: 0px !important;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0px !important;
}

.blueContainer {
  width: 50%;
  height: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.blueRect {
  width: 80%;
  height: 100%;
  background-color: #3b358a;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.blueRectText {
  font-family: "Red Hat Display" !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #fff !important;
  width: 50% !important;
}

.blueTri {
  width: 22%;
  height: 100%;
  /* margin-left: -1px !important;
  clip-path: polygon(100% 50%, 0px 0px, 0px 100%);
  border: 5px #3b358a !important;
  background-color: #3b358a; */
}

.signUpContainer {
  height: 100%;
  width: 100%;
  padding: 0px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header {
  width: 85%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 3vh !important;
  z-index: 1;
}

.headerButton {
  background-color: #f6f6f6 !important;
  text-transform: none !important;
  color: #201f28 !important;
  border-radius: 4px;
}

.headerText {
  font-family: "Satoshi-Variable" !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #77767c !important;
}

.headerButtonContainer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
}

.title {
  text-align: left;
}

.myTaskButton {
  background-color: #eeedf7 !important;
  color: #201f28 !important;
  width: 128px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.spaceContainer {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.spacer {
  width: 50%;
  height: 100%;
}

.formContainer {
  width: 45%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .headerContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .triangleImage {
    height: 768px;
    margin-left: 0px !important;
    padding-left: 0px !important;
    transform: translateX(-10px);
  }

  .blueRect img {
    width: 100%;
  }

  .blueRectText {
    font-family: "Red Hat Display" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #fff !important;
    width: 70% !important;
  }

  .blueContainer {
    width: 45%;
    height: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .header {
    width: 90%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 3vh !important;
    z-index: 1;
  }

  .headerButtonContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
    padding-right: 3vw;
  }

  .spacer {
    width: 50%;
    height: 100%;
  }

  .formContainer {
    width: 50%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20vh;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .headerContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .blueContainer {
    display: none !important;
  }

  .spacer {
    width: 0px !important;
  }

  .formContainer {
    width: 100%;
    padding-top: 10vh;
  }

  .header {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 3vh 3vw !important;
    z-index: 1;
    background-color: #3b358a;
  }

  .headerButtonContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
    padding-right: 3vw;
  }

  .headerText {
    font-family: "Satoshi-Variable" !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
  }

  .headerLogo {
    margin-left: 3vw;
  }
}
