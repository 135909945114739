.container {
  display: flex !important;
  margin-top: 2vh !important;
  min-width: 40% !important;
  padding: 20px 15px !important;
  /* flex-direction: column !important; */
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 20px !important;
  border-radius: 12px !important;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04)) !important;

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.title {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
  text-align: left !important;

  letter-spacing: 0.25px !important;
}
.suggestion {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 14px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 0.25px !important;
}
@media screen and (max-width: 1230px) {
  .title {
    margin-bottom: 2px;
    line-height: 12px !important; /* 114.286% */
    font-size: 12px !important;
  }
  .suggestion {
    font-size: 11px !important;
    line-height: 12px !important;
  }
}
