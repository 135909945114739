.keepGoingContainer {
  height: 100%;
  width: 100%;
  display: flex !important;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
  
}

.emoji {
  height: 24px;
  width: 24px;
  align-self: center;
}

.imageContainer {
  width: 20px !important;
  height: 35px !important;
}

.text {
  font-family: "Red Hat Display" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #fff !important;
  text-align: center !important;
}

.attachedDocument {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 12px;
  gap: 16px !important;
  z-index: 10;
  width: 90%;
}
.attachedDocumentEmpty {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  gap: 16px !important;
  z-index: 10;
  width: 90%;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.name {
  text-align: left;
  font-family: "Red Hat Display" !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #201f28 !important;
}

.description {
  text-align: left;
  text-overflow: ellipsis !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  color: #636269 !important;
  white-space: initial !important;
  word-wrap: break-word !important;
  width: 100%;
}
