.tableContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  padding: 0px !important;
  height: fit-content;
  box-shadow: none;
}

.headerContainer {
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
}

.btn {
  width: 100%;
  border: 1.5px solid #e9e9e9 !important;
  color: #77767c !important;
}

.btn:hover {
  background-color: none;
}

.btnSelected {
  width: 100%;
  background-color: #eeedf7 !important;
  border: 1.5px solid #e9e9e9 !important;
  color: #3b358a;
}

.tableSection {
  width: 100%;
  padding: 0;
  height: 400px;
  overflow-y: scroll;
}

.tableSection table {
  border-collapse: collapse;
  table-layout: auto !important;
  width: 100%;
  padding: 0;
}

thead {
  background-color: #e9e9e9;
  text-align: left;
  color: #77767c;
  font-size: 11px;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

thead th {
  padding: 16px 0px 16px 30px;
}

.tbody {
  width: 100%;
}

td {
  text-align: end;
  height: 80px;
}

tr {
  border-bottom: 1px solid #e9e9e9;
}

.rankedTable td {
  padding: 10px;
  padding-right: 40px !important;
}

.rankedTable td:nth-child(1) {
  padding-right: 0px !important;
}

* {
  font-family: "Satoshi-Variable" !important;
  /* font-size: 14px; */
}
