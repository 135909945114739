/* SwitchContainer.css */
.buttonActive {
  /* padding: 4px 8px 4px 8px !important; */
  width: 49%;
  border-radius: 8px !important;
  /* gap: 10px !important; */
  color: #ffffff !important;
  background: #ffffff30 !important;
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
}
.buttonInActive {
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
  width: 49%;
  text-transform: none !important;
  /* padding: 4px 8px 4px 8px !important; */
  border-radius: 8px !important;
  color: rgba(255, 255, 255, 0.6) !important;
  /* gap: 10px !important; */
  background: #ffffff20 !important;
}
@media (min-width: 1023px) and (max-width: 1230px) {
  .buttonActive {
    width: 48%;
    font-size: 9px !important;
    /* padding: 1px 4px 1px 4px !important; */
  }
  .buttonInActive {
    width: 48%;
    font-size: 9px !important;
  }
}
