.container {
  padding: 3vh 0px 100px 0px !important;
  margin: 0px;
  background-color: none;
  width: 100%;
}

.myTaskButton {
  background-color: #eeedf7 !important;
  color: #201f28 !important;
  width: 128px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}
.AddDocButton {
  height: 40px !important;
  width: 100px !important;
  background-color: #464092 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  text-transform: none !important;
}
.DeleteButton {
  height: 40px !important;
  width: 100px !important;
  background-color: #ffffff !important;
  border: 1px solid #464092 !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #464092 !important;
  text-transform: none !important;
}

.myTaskButton:hover {
  background-color: inherit;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 95% !important;
  align-items: center;
  justify-content: space-between !important;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.secondaryContainer {
  width: 100%;
  padding: 0px !important;
  margin-top: 30px;
  border-radius: 4px;
}

.libraryContainer {
  width: 100%;
  padding: 0px;
  box-shadow: none;
  background-color: white;
  border-radius: 4px;
  box-shadow: none;
  padding: 0px !important;
}

.libraryFlex {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  padding: 0px !important;
}

.menuSection {
  height: 800px;
  width: 23%;
  border-right: 1px solid #e9e9e9;
  padding: 0px !important;
}

.searchFieldContainer {
  width: 100%;
  padding: 32px 16px 16px 32px;
}

.listItem {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  opacity: 0.8;
  cursor: pointer;
}

.listItem p {
  color: var(--dark-inverted-bg-100, #fff) !important;

  /* H5/bold */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}

.listItemSelected {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
  width: 95%;

  cursor: pointer;
}

.listItemSelected p {
  color: var(--neutral-1, #fff) !important;

  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}

.cardSection {
  width: 100%;
  height: 800px;
  padding: 20px;
}

.cardListContainer {
  height: 100%;
  padding: 35px 0px 0px 0px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cardSection .titleContainer {
  padding: 0px !important;
}

@media only screen and (max-width: 1465px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 100% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .menuSection {
    width: 35% !important;
  }

  .cardSection .titleContainer {
    padding: 0px !important;
  }

  .cardSection {
    padding-right: 0px;
  }

  .cardListContainer {
    height: 90% !important;
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 100% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .menuSection {
    width: 50% !important;
  }

  .cardSection .titleContainer {
    padding: 0px !important;
  }

  .cardListContainer {
    height: 90% !important;
  }
}
