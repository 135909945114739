.welcomeContainer {
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  gap: 16px;
}

.logo {
  width: 130px !important;
}

.welcome {
  font-family: "Red Hat Display" !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #201f28 !important;
}

.starting {
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #3b358a !important;
}
