.container {
  height: 100% !important;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  background-color: none;
  border-top: 1px solid #e9e9e9;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  padding: 0px;
}

.heading {
  font-family: "Red Hat Display";
  line-height: 21px;
  letter-spacing: 0.0025em;
  text-align: center;
  color: #636269;
}

.subContainer {
  width: "100%";
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  font-family: "Satoshi-Variable";
  line-height: 22px;
  letter-spacing: 0.005em;
  text-align: center;
  height: 44px;
  color: #77767c;
  width: 378px;
}

.card {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  gap: 8px;
  background-color: #ffffff;
  padding-left: 24px;
}

.connect {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border-radius: 4px;
}

.name {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}

.divider {
  width: 378px;
}

.dividerText {
  color: #77767c;
  font-family: "Satoshi-Variable";
}

.chip {
  background-color: #ecf7f0 !important;
  color: #328e51 !important;
  border: none !important;
  width: fit-content;
  height: 26px !important;
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  padding: 4px 8px 4px 8px !important;
  border-radius: 30px !important;
}

.chipDefault {
  background-color: #f6f6f6 !important;
  color: #636269 !important;
  border: none !important;
  width: fit-content !important;
  height: 26px !important;
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  padding: 4px 8px 4px 8px !important;
  border-radius: 30px !important;
}

.chipDefault span {
  font-size: 13px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
}

.chipDefaultContainer {
  width: 80%;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 8px;
}
