.inMeetingInnerContainer {
  width: 100%;
  background-color: black;
  display: flex !important;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0px !important;
  height: 100%;
  gap: 8px;
  /* position: relative; */
  /* overflow-y: scroll; */
}
.graphXaxis {
  color: rgba(255, 255, 255, 0.32) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 10px !important;
  font-style: normal !important;
  margin-left: 5px !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 175% */
  letter-spacing: 0.8px !important;
  text-transform: uppercase !important;
}
.inMeetingContainer {
  width: 100%;
  z-index: 10;
  background-color: black;
  /* display: flex !important; */
  /* z-index: 1000; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: start; */
  padding: 0px !important;
  height: 100%;
  /* gap: 8px; */
  /* position: relative; */
  overflow-y: auto;
}
.motionHeadings {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 17px !important;
  text-align: right;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.advanceScoreValueTitle {
  width: 85% !important;
  margin-top: 05px !important;
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  flex-wrap: wrap;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.motionTitle {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Capitol Heading 25 */
  font-family: Satoshi Variable;
  font-size: 12px !important;
  margin-top: 05px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.emptyMotionHeadings {
  color: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24)) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.advanceScoreValue {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.donutChartHeading {
  color: #ffffff50 !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 12px !important;
  font-style: normal !important;
  margin-top: 10px !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}

.slider {
  z-index: 100;
}

.blueContainer {
  position: absolute;
  width: 100%;
  background-color: black;
  z-index: 1;
}
.saveButton {
  border-radius: 4px !important;
  border: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;
  background: #464092 !important;
  color: var(--neutrals-primary-white, #fff) !important;
  font-family: Satoshi Variable;
  font-size: 12px !important;
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12) !important;
}
.addNotes {
  border-radius: 8px;

  color: #ffffff;
  border: 1px solid var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  width: 75%;
  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.statCardIcon {
  height: 16px !important;
  width: 18px !important;
  color: #ffffff;
}

.topBar {
  width: 90%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999 !important;
  padding: 8px 4px !important;
}

.meetingTitle {
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
}

.feedbackContainer {
  width: 90%;
  /* display: flex; */
  /* background-color: #464092; */
  /* justify-content: space-between; */
  /* height: 40px !important; */
  z-index: 10;
  /* padding: 11px 0px !important; */
  /* border: 1px solid rgba(255, 255, 255, 0.34); */
}

.buttonGroupContainer {
  z-index: 10;
  padding: 0px !important;
  width: 100%;
}

.tabsContainer {
  z-index: 10;
  padding: 0px !important;
  width: 100%;
}

.statCardContainer {
  z-index: 100;
  padding-bottom: 1vh;
}
.addNotesContainer {
  display: flex;
  justify-content: space-between;
  /* z-index: 99; */
}
.statCardRow {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 16px !important;
  width: 100% !important;
  padding: 12px 0px !important;
}

.resonanceLabel {
  z-index: 100;
  color: #ffffff;
  font-size: 14px !important;
  font-family: "Satoshi-Variable";
  font-weight: 400 !important;
  margin-block-start: 0px !important;
}
.mainContainer {
  width: 100%;
}

.gaugeContainerGreen {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  z-index: 100;
  background: radial-gradient(
    90.94% 93.22% at 50% 50%,
    rgba(42, 209, 149, 0.21) 0%,
    rgba(40, 202, 144, 0) 100%
  );
  /* height: 34vh; */
  padding: 10px;
  border-radius: 8px;
  justify-content: center;

  /* align-items: start !important; */
}
.gaugeTitle {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 100% */
  letter-spacing: 0.25px !important;
}
.steps {
  color: #fff !important;
  text-align: left !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 100% */
  letter-spacing: 0.25px !important;
}
.gaugeContainerRed {
  display: flex;
  flex-direction: column;
  margin-top: 05vh;
  z-index: 100;
  background: radial-gradient(
    90.94% 93.22% at 50% 50%,
    rgba(229, 26, 63, 0.44) 0%,
    rgba(229, 26, 63, 0) 100%
  );
  /* height: 34vh; */
  padding: 10px;
  border-radius: 8px;
  justify-content: center;

  /* align-items: start !important; */
}
.gaugeContainerYellow {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  z-index: 100;
  background: radial-gradient(
    90.58% 93.72% at 50% 50%,
    rgba(244, 190, 79, 0.31) 0%,
    rgba(244, 190, 79, 0) 100%
  );
  /* height: 34vh; */
  padding: 10px;
  border-radius: 8px;
  justify-content: center;

  /* align-items: start !important; */
}

.advanceTitles {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
}
.suggestionBarContainer {
  display: flex;
  height: 16vh;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll !important;
  gap: 10px;
  align-items: center;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important; /* IE and Edge */
}
.suggestionBarContainer::-webkit-scrollbar {
  display: none !important;
}
.responseDescription {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 16px !important;
  padding: 10px 30px 10px 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: 0.25px !important;
}
.askCopilot {
  width: 100% !important;
  margin-top: 15px !important;
  /* height: 46px !important; */
  font-size: 16px !important;
  /* background-color: #464092 !important; */
  /* padding: 12px !important; */
  /* color: #c3c2c5 !important; */
  /* flex-direction: column !important; */
  border-radius: 12px !important;
  border: 3px solid rgba(79, 79, 79, 0.32) !important;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04)) !important;

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-transform: none !important;
  /* gap: 16px !important; */
  /* align-self: stretch !important; */
}
.fixedTextField {
  position: fixed;
  width: 28.9%;

  /* display: flex; */
  padding: 10px 10px 5px 10px;
  /* align-items: center;
  justify-content: center; */
  height: 8vh;
  overflow-x: hidden !important;
  bottom: 0;
  border-top: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  background: "var(--dark-surface-1, #141415);";
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1); /* Add a shadow for the fixed text field */
}
.statsFlex {
  width: 90% !important;
  /* display: flex !important;
  background-color: #fff;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important; */
}

.donutChartGrid {
  width: 100% !important;
  display: flex;
  margin-top: 30px;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  /* margin-left: 2vw; */
}
.scoreValue {
  font-family: Satoshi Variable;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
  color: #ffffff;
}

.scoreText {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: left !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.graphText {
  font-family: Satoshi Variable;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  line-height: 14px !important;
  letter-spacing: 0.1em !important;
  text-align: center !important;
  color: #ffffff50 !important;
  margin-top: 10px !important;
}
.advanceStatsButton {
  border: 1px solid #ffffff14 !important;
  /* background: #FFFFFF14 !important; */
  box-shadow: 0px 8px 30px 0px #4a42ad1f !important;
  /* height: 48px !important; */
  width: 100%;
  font-family: Satoshi Variable;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;

  padding: 13px 20px 13px 20px !important;
  border-radius: 4px !important;
  /* border: 1px !important; */
  gap: 8px !important;
  margin-top: 50px !important;

  text-transform: none !important;
}

.donutChartGrid .smallContainer {
  height: 120px;
  width: 80% !important;
}

.dropDownContainer {
  border: 1px solid #ffffff;
  width: 93%;
  z-index: 999;
  margin-top: 6vh;
  border-radius: 3px;
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  align-items: center;
  padding-bottom: 0.5vh;
}
.arrowSize {
  width: 16px !important;
  height: 16px !important;
}
.valueContainer {
  height: 12vh;

  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.07));
  padding: 15px 2px;
  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

/* .verticalBarContainer {
  height: 180px;
  width: 10px;
  padding: 0px !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  transform: translate(-5px,2px);
}

.verticalBar {
  background-color: #f6f6f6;
  width: 10px;
} */
@media screen and (max-width: 1230px) {
  .gaugeTitle {
    font-size: 18px !important;
  }
  .scoreText {
    font-size: 10px !important;
  }
  .responseDescription {
    padding: 0;
    font-size: 14px !important;
    /* background-color: aqua; */
  }
  .gaugeContainerGreen {
    /* margin-top: 10vh; */
    height: 28vh;
    padding: 6px;
  }
  .gaugeContainerRed {
    /* margin-top: 10vh; */
    height: 28vh;
    padding: 6px;
  }
  .gaugeContainerYellow {
    /* margin-top: 10vh; */
    padding: 06px;
    height: 28vh;
  }
  .arrowSize {
    width: 10px !important;
    height: 10px !important;
  }
  .donutChartHeading {
    font-size: 8px !important;
  }
  .advanceScoreValueTitle {
    font-size: 09px !important;
  }
  .advanceScoreValue {
    font-size: 14px !important;
  }
  .scoreValue {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .motionHeadings {
    font-size: 12px !important;
  }
  .motionTitle {
    font-size: 09px !important;
  }
}
