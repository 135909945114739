.attachedDocument {
  width: 100%;
  /* height: 90px; */
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  align-items: flex-start;
  gap: 20px !important;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.nameSales {
  text-align: left;
  font-family: "Satoshi-Variable" !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  color: #cc7638 !important;
  text-transform: uppercase !important;
}

.nameDoc {
  text-align: left;
  font-family: "Satoshi-Variable" !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  color: #3b358a !important;
  text-transform: uppercase !important;
}

.description {
  text-align: left;
  width: 100%;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #636269 !important;
}

.timeContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.time {
  font-family: "Satoshi-Variable" !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #77767c !important;
}
