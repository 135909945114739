.container {
  height: 100%;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.leftContainer {
  padding: 20px;
  position: relative;
  width: 50%;
  height: 100%;

  text-align: left;
}
.tab {
  display: flex;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  /* border-bottom: 2px solid var(--dark-inverted-bg-100, #fff); */
}
.tabText {
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.tabContainer {
  display: flex;
  padding: 12px 20px 0px 0px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.rightContainer {
  padding: 20px;
  min-height: 80vh;
  width: 50%;
  text-align: left;
  background: linear-gradient(
    90deg,
    #151515 -122.7%,
    rgba(73, 73, 73, 0) 45.67%
  );
  overflow-y: auto;
}
.fixedContainer {
  position: sticky;
  width: 100%;
  bottom: 0;
  display: flex;
  height: 10vh;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #030204;
}
.titleContainer {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.meetingTitle {
  color: #fff !important;
  text-overflow: ellipsis;

  /* H3/medium */
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important; /* 133.333% */
}
.talkingPointsHeading {
  color: var(--neutrals-muted, #77767c) !important;

  /* Body/Medium_14 */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 22.4px */
  letter-spacing: 0.07px !important;
}
.medicineName {
  color: var(--light-white, #fff) !important;

  /* Headings/H4_16 */
  font-family: Red Hat Display !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 0.04px !important;
}
.productContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.suggestionBarContainer {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll !important;
  gap: 10px;
  align-items: center;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important; /* IE and Edge */
}
.suggestionBarContainer::-webkit-scrollbar {
  display: none !important;
}
.suggestionBarContainerVertical {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll !important;
  gap: 10px;
  align-items: center;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important; /* IE and Edge */
}
.meetingTime {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  text-overflow: ellipsis;

  /* Body/bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}
.meetingId {
  overflow: hidden;
  color: var(--light-primary-bg, #9747ff) !important;
  text-overflow: ellipsis !important;

  /* Body/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 128.571% */
}
.videoContainer {
  display: flex;
  height: 35vh;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.detailsText {
  color: rgba(255, 255, 255, 0.5) !important;

  /* Footnote/description */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 166.667% */
}
/*Overview tab */
.sectionHeader {
  display: flex;
  height: 36px;
  align-items: center;
  margin-top: 32px;
  gap: 10px;
  align-self: stretch;
}
.buttonContainer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  cursor: pointer;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
}
.sectionHeaderText {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 100% */
  letter-spacing: 1.6px !important;
  text-transform: uppercase !important;
}
.meetingInfoHeading {
  color: #fff !important;
  text-align: center !important;
  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.meetingInfoSuggestion {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;
  /* Body/regular */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 128.571% */
}
.chartContainer {
  display: flex;
  align-items: flex-start;
  gap: 2vw;
  padding: 3vh 0;
  align-self: stretch;
}
.graphTitle {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 100% */
  letter-spacing: 0.25px !important;
}
.graphXaxis {
  color: rgba(255, 255, 255, 0.32) !important;
  text-align: left !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 10px !important;
  font-style: normal !important;
  margin-left: 5px !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 175% */
  letter-spacing: 0.8px !important;
  text-transform: uppercase !important;
}
.graphHeading {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.overviewContainer {
  display: flex;
  flex-direction: column;
}
.talkingPointContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.talkingPointTitle {
  color: var(--neutrals-muted, #77767c);

  /* Body/Medium_14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.07px;
}
.talkingPointDescription {
  color: var(--light-white, #fff) !important;
  width: 40%;
  /* Body/Medium_14 */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 22.4px */
  letter-spacing: 0.07px !important;
}
.overallScoreParentContainer {
  display: flex;

  padding: 4px 24px 8px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
}
.overallScoreContainer {
  display: flex;
  width: 22%;
  height: 15vh;
  padding: 20px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.overallScoreHeading {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  width: max-content;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: 0.25px !important;
}
.overallScoreTitle {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
@media only screen and (max-width: 1320px) and (min-width: 876px) {
  .videoContainer {
    height: 25vh !important;
  }
}
