main {
  width: 100%;
  margin: auto;
  text-align: center;
  height: 100vh;
}
.zoomApp {
  height: 100vh !important;
  background-color: #242424;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zmwebsdk-makeStyles-chatCustomize-10 {
  transform: translate3d(200px, 50px, 0px) !important;
}
.zoom-MuiPopper-root {
  transform: translate3d(0, 50px, 0px) !important;
  height: 70vh !important;
  max-height: 700px !important;
  overflow-y: scroll;
  width: 100%;
}
.css-aq4w2k {
  transform: translateX(25vw) !important;
}
.zoom-MuiBox-root .css-1ftso8c .ReactVirtualized__Grid {
  height: unset !important;
}

.zmwebsdk-makeStyles-wrap-44.zmwebsdk-makeStyles-wrap-49 {
  width: 100%;
}
.zmwebsdk-makeStyles-settingCustomize-11 {
  transform: translate3d(150px, 50px, 0px) !important;
}
.zmwebsdk-MuiBox-root.zmwebsdk-MuiBox-root.zmwebsdk-makeStyles-root-78.zmwebsdk-makeStyles-root-202 {
  transform: translate3d(-500px, 0px, 0px) !important;
}
.zmwebsdk-MuiBox-root.zmwebsdk-MuiBox-root.zmwebsdk-makeStyles-root-78 {
  transform: translate3d(-500px, 0px, 0px) !important;
}
.zmwebsdk-MuiBox-root.zmwebsdk-MuiBox-root.zmwebsdk-makeStyles-root-78.zmwebsdk-makeStyles-root-214 {
  transform: translate3d(-500px, 0px, 0px) !important;
}

/* .zmwebsdk-makeStyles-root-203 {
  transform: translate3d(-315px, 80px, 0px) !important;
} */
/* .zmwebsdk-makeStyles-root-217 {
  transform: translate3d(-405px, 80px, 0px) !important;
} */

.zmwebsdk-makeStyles-videoCustomize-1.zmwebsdk-makeStyles-videoCustomize-8.react-draggable {
  width: 100%;
}
.zmwebsdk-makeStyles-root-192 {
  width: 100% !important;
  left: 0px !important;
}
.zmwebsdk-MuiCardMedia-root.zmwebsdk-makeStyles-avatar-197.zmwebsdk-MuiCardMedia-media.zmwebsdk-MuiCardMedia-img {
  width: 20%;
}
main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}

.zmwebsdk-MuiPaper-elevation1 {
  box-shadow: none !important;
}

.zmwebsdk-makeStyles-root-194 {
  height: 100vh !important;
  width: 75vw !important;
}

.zmwebsdk-makeStyles-root-28 {
  height: 100vh !important;
  width: 75vw !important;
}

.zmwebsdk-makeStyles-root-128 {
  display: none;
}

.zmwebsdk-makeStyles-panel-104 {
  transform: translate(-800px, 50px) !important;
}
.zmwebsdk-makeStyles-root-48 {
}
@media screen and (min-width: 2500px) {
  .zmwebsdk-makeStyles-root-48 {
    height: 1250px !important;
  }
}
@media screen and (max-height: 800px) {
  .zmwebsdk-makeStyles-root-48 {
    height: 570px !important;
  }
}
