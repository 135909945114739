.container {
  display: flex;
  padding: 2px 10px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 30px;
}
.title {
  /* Body/medium */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 128.571% */
}
