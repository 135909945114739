.container {
  padding: 3vh 0px 20px 0px !important;
  margin: 0px;
  background-color: none;
  width: 100%;
}

.myTaskButton {
  background-color: #eeedf7 !important;
  color: #201f28 !important;
  width: 128px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}
.tableRowIndex{
color: rgba(255, 255, 255, 0.50);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Satoshi Variable";
font-size: 10px;
text-align: center !important;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 160% */
letter-spacing: 1px;
}

.myTaskButton:hover {
  background-color: inherit;
}

.titleContainer {
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.singleStatContainer {
  margin-top: 1vh;
  width: 100%;
  background-color: #ffffff;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border-radius: 4px;
}

.statBox {
  width: 25%;
}

.dataTableContainer {
  width: 100% !important;
  padding: 0px !important;
  margin-top: 50px;
}

.firstTd {
  transform: translate(20px, 0px) !important;
  color: rgba(255, 255, 255, 0.8) !important;

  /* Body/medium */
  font-size: 14px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 128.571% */
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .singleStatContainer {
    width: 100% !important;
    margin-left: 0px !important;
    padding-left: 10px !important;
  }

  .dataTableContainer {
    width: 100% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .firstTd {
    font-family: "Satoshi-Variable" !important;
    color: #201f28 !important;
    transform: translate(-70px, 0px) !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .singleStatContainer {
    width: 100% !important;
    margin-left: 0px !important;
    padding: 0px 0px !important;
    flex-direction: column;
    align-items: flex-start;
    background-color: inherit !important;
    gap: 20px;
  }

  .statBox {
    width: 100%;
    background-color: #ffffff;
    padding: 5px 5px !important;
    border-radius: 4px;
  }

  .dataTableContainer {
    width: 100% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .firstTd {
    font-family: "Satoshi-Variable" !important;
    color: #201f28 !important;
    transform: translate(-20px, 0px) !important;
  }
}
