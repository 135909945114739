.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  /* height: 20vh; */
  padding: 20px;
  gap: 20px;
  align-self: stretch;
  border-radius: 20px 0px 0px 20px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
}
.dayTitleText {
  text-align: left !important;
  color: var(--golden-purple-1, #f9f0ff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important; /* 133.333% */
  letter-spacing: -0.48px !important;
}
.timeContainer {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
}
.meetingContainer {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  padding: 0px 20px;
  align-self: stretch;
}
.meetingCountText {
  color: var(--neutral-7, #8c8c8c) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important; /* 160% */
  letter-spacing: -0.4px !important;
}
.meetingTimeContainer {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 20%;
}
.meetingTimeText {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: Satoshi Variable;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}
.meetingInnerContainer {
  display: flex;
  width: 50%;
  /* height: 130px; */
  padding: 20px;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.meetingDurationText {
  color: rgba(255, 255, 255, 0.4) !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.meetingTitle {
  overflow: hidden !important;
  color: #fff !important;
  text-overflow: ellipsis !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 140% */
}
.meetingDescription {
  overflow: hidden !important;
  color: rgba(255, 255, 255, 0.5) !important;
  text-overflow: ellipsis !important;

  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.meetingTimeInfo {
  color: #57f9c8 !important;
  font-feature-settings: "clig" off, "liga" off !important;

  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 123.077% */
  letter-spacing: 1.3px !important;
  text-transform: uppercase !important;
}
