.container {
  padding: 0vh 0vh 100px 0vh !important;
  background-color: none;
  width: 90% !important;
  /* margin-left: 50px !important; */
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 0vh 0vh 0vh 0vh;
  z-index: 1;
  height: 65px;
}

.title {
  text-align: left;
  color: #201f28;
}

.backButton {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1vw;
}

.secondaryContainer {
  width: 100%;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  padding: 0px !important;
  margin-top: 50px;
  height: auto;
}
.secondaryContainerOverview {
  width: 100%;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  padding: 0px !important;
  margin-top: 50px;
  height: auto;
}

.avatarButtonGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}

.analyticsBox {
  height: 346px;
  width: 684px;
}

.userInfoBox {
  height: 346px;
  width: 480px;
  background-color: #fff;
  border-radius: 4px;
box-shadow: none;
}

.titleButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.titleButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 38px;
  height: 40px;
  background: #4a42ad;
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid #3b358a;
  cursor: pointer;
}

.publishButton {
  background-color: #4a42ad !important;
  color: #fff !important;
  width: 135px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Satoshi-Variable" !important;
  padding: 0px !important;
  box-shadow: none !important;
  border-radius: 4px 0px 0px 4px !important;
}

.deleteProspect img {
  margin-top: 0px;
}

.tableContainer {
  padding: 0px !important;
  margin-top: 50px;
}

.backgroundContainer {
  position: absolute;
  display: block;
  background-color: #fff;
  height: 170px;
  width: 94%;
}

@media only screen and (min-width: 1000px) {
  .backgroundContainer {
    width: 96%;
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 1200px) {
  .backgroundContainer {
    width: 94%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 999px) {
  .backgroundContainer {
    width: 100%;
    overflow-x: hidden;

  }
}


.productTemplateBox {
  background-color: #fff;
  width: 684px;
  box-shadow: none !important;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}

.recordingAnalysis {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
}

.videoSection {
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px !important;
  border-right: 1px solid #e9e9e9;
}

.videoContainer {
  width: 100%;
  position: relative;
}

.videoContainer video {
  width: 100%;
  height: fit-content;
  border-top-left-radius: 8px;
}

.transcriptContainer {
  width: 100%;
  height: 55%;
}

.feedbackSection {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.videoControls {
  position: absolute;
  width: inherit;
  height: 55%;
  z-index: 1;
  bottom: 0px !important;
  background: rgba(32, 31, 40, 0.7);
  backdrop-filter: blur(20px);
}

.speakerOptions {
  position: absolute;
  width: inherit;
  height: 10%;
  z-index: 1;
  display: none;
  bottom: 0px !important;
  background-color: #201f28;
}

.speakerText {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Red Hat Display" !important;
}

.speakerArrow {
  cursor: pointer;
}

.accordionContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  color: #fff;
  flex-direction: column;
}

.accordionMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 24px;
  border-top: 1px solid #e6e6e6;
}

.accordionMenuOpen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.playerIconGrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.audioGraph {
  height: 50%;
  width: 100%;
}

.playerOptions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 24px;
}

.speed {
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.accordionContainerOpen {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 8px 24px;
  color: #fff;
  gap: 10px;
  border-top: 1px solid #e6e6e6;
}

.personName {
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #77767c !important;
}

.personTime {
  font-family: "Satoshi-Variable" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #77767c !important;
}

.timeBarSales {
  display: block;
  width: 100%;
  height: 10px;
  background-color: #3b358a;
  border-radius: 4px;
}

.timeBarClient {
  display: block;
  width: 100%;
  height: 10px;
  background-color: #cc7639;
  border-radius: 4px;
}

.speakerOptionsOpen {
  position: absolute;
  width: inherit;
  height: 50%;
  z-index: 1;
  bottom: 0px !important;
  background-color: #201f28;
}

.salesPersonTalkTime {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0px 0px;
}

.playerOptionsOpen {
  display: none;
}

.talkTime {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.talkTimeContainer {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 100% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
    flex-wrap: wrap;
    gap: 30px;
  }

  .container {
    height: 465vh !important;
  }

  .analyticsBox {
    width: 98%;
  }

  .userInfoBox {
    width: 98%;
  }

  .productTemplateBox {
    width: 98%;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
    flex-wrap: wrap;
    gap: 50px;
  }

  .container {
    height: 470vh !important;
  }

  .analyticsBox {
    width: 98%;
  }

  .userInfoBox {
    width: 98%;
  }

  .productTemplateBox {
    width: 98%;
  }
}
