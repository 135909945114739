.container {
  display: flex;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
}
.cardTitle {
  color: #fff !important;
  font-size: 16px !important;
  display: flex;
  flex-wrap: wrap;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.cardSubtitle {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}
