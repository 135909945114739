
.innerContainer{
    display: flex;
padding: 20px;
align-items: center;
gap: 14px;
justify-content: space-between;
border-radius: 12px;
background: linear-gradient(133deg, #9747FF -86.89%, rgba(151, 71, 255, 0.42) -18.83%, rgba(151, 71, 255, 0.06) 43.79%);
align-self: stretch;
}
.title{
    color: rgba(255, 255, 255, 0.80) !important;
text-align: left !important;
/* H5/bold */
font-size: 16px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: 24px !important; /* 150% */
}
.subtitle{
    color: rgba(255, 255, 255, 0.50) !important;
text-align: left !important;


/* Footnote/description */
font-size: 12px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 20px !important; /* 166.667% */
}
.manageLink{
    color: #FFF !important;

/* Body/medium */
font-size: 14px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 18px !important; /* 128.571% */
}