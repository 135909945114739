input:disabled {
  color: rgba(195, 194, 197, 1) !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  box-shadow: none !important;
}
.textarea{
  color: rgba(195, 194, 197, 1) !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  resize: none;
  outline: none;
}
.inputContainer {
  border-radius: 12px !important;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04)) !important;
  box-shadow: none !important;
  color: rgba(195, 194, 197, 1) !important;

  /* border-bottom: 2px solid #eeedf7 !important; */
}

.inputContainer:has(input:focus) {
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
}

.input::-webkit-input-placeholder {
  display: none !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.menuItem {
  height: 46px;
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  color: #636269 !important;
  font-weight: 500 !important;
}

.menuItem:hover {
  height: 46px;
  font-family: "Satoshi-Variable" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #201f28 !important;
  background-color: transparent !important;
}

.menuItemContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px 16px 0px 0px;
  border-radius: 4px;
}

.menuItemContainer:hover {
  background-color: #fafafa;
}

.addText {
  display: none !important;
}

.menuItemContainer:hover .addText {
  display: block !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  color: #3b358a !important;
  font-size: 14px !important;
  cursor: pointer;
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .inputContainer {
    width: 350px !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .inputContainer {
    width: 350px !important;
  }
}
