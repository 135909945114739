.graphHeading {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 14px !important; /* 70% */
  letter-spacing: 0.25px !important;
}
.iconContainer {
  transform: translate(0vw, -10vh) !important;
}
.graphValue {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 24px !important; /* 75% */
  letter-spacing: 0.25px !important;
}

.valueContainer {
  transform: translate(0px, -3vh);
}
@media (min-width: 1023px) and (max-width: 1230px) {
  .iconGroup {
    width: 62px !important;
    height: 33px !important;
  }
  .graphValue {
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 16px !important; /* 75% */
  }
  .graphHeading {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important; /* 70% */
    letter-spacing: 0.25px !important;
  }
  .valueContainer {
    transform: translate(0px, -2.5vh);
  }
  .iconContainer {
    transform: translate(0vw, -6vh) !important;
  }
}

