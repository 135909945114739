.mainContainer {
  display: flex;
  /* height: 192px; */
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(
    162deg,
    #7e51ff -81.4%,
    rgba(126, 81, 255, 0.28) 2.29%,
    rgba(185, 97, 255, 0) 82.57%
  );
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  margin-bottom: 20px;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.moduleContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
.subtitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: left;
  font-weight: 500 !important;
  opacity: 0.6 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: -0.28px !important;
}
.title {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  text-align: left;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  letter-spacing: -0.48px !important;
}
.flexRow {
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  align-items: center;
  /* align-self: stretch; */
}
.scoreIconText {
  color: var(--light-primary-bg, #9747ff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 150% */
}
.timeText {
  color: var(--light-white, #fff) !important;
  opacity: 0.6 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 150% */
}
