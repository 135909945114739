.container {
  padding: 0px !important;
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 4px !important;
  height: 100%;
}

.greeting {
  height: 82px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 32px;
  font-family: "Red Hat Display" !important;
}

.btn {
  width: 100%;
  border: 1.5px solid #e9e9e9 !important;
  color: #77767c !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Satoshi-Variable" !important;
}

.btn:hover {
  background-color: none;
}

.btnSelected {
  width: 100%;
  background-color: #eeedf7 !important;
  border: 1.5px solid #e9e9e9 !important;
  color: #3b358a;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Satoshi-Variable" !important;
}

.statsContainer {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.singleStatContainer {
  width: 208px;
  height: 100%;
}

.graphSection {
  width: 311px;
  height: 189px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 0px 0px 16px;
}

.graphSectionTextContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.graphSectionTextBox {
  display: flex;
  flex-direction: row;
}

.graphContainer {
  height: 138px;
  width: 200px;
  width: 70%;
}

.graph {
  width: 25%;
}

.tip {
  width: 100%;
  margin-top: 10px;
  padding-top: 25px;
  padding-left: 0px !important;
  display: flex;
  align-items: flex-start;
}

.tipText {
  color: "#77767C";
  padding-left: 0px;
}
