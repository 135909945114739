.container {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  margin: 10px;
  gap:8px;
}

.subtext {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px !important;
  flex-direction: row;
  gap: 4px;
}

.imageBox {
  display: flex;
  height: 48px !important;
  width: 48px !important;
  background-color: #f6f6f6;
  border-radius: 4px;
  justify-content: center !important;
  align-items: center;
}

.imageBox img {
  height: 32px;
  width: 32px;
}

.textBox {
  display: "flex" !important;
  flex-direction: "column" !important;
  text-align: "start" !important;
  justify-content: "start" !important;
  margin-left: 8px;
}

.textBox * {
  text-align: left;
}
