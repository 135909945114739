.container{
    display: flex;
padding: 20px;
flex-direction: column;
align-items: flex-start;
gap: 14px;
align-self: stretch;
    border-radius: 12px;
background: linear-gradient(133deg, #9747FF -86.89%, rgba(151, 71, 255, 0.42) -18.83%, rgba(151, 71, 255, 0.06) 43.79%);
}
.managerCompany{
    color: var(--light-primary-bg, #9747FF) !important;
text-align: center !important;
font-feature-settings: 'clig' off, 'liga' off !important;

/* Captiol Heading 50 */
font-size: 12px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: 16px !important; /* 133.333% */
letter-spacing: 1.2px !important;
text-transform: uppercase !important;
}