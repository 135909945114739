.container {
  margin: 0px;
  display: flex;
  height: 100%;
  width: 100%;
}
.wrapper1 {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-top: 3vh;
  padding-left: 20px;
  padding-right: 20px;
  gap: 3vh;
}
.wrapper2 {
  width: 75%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--neutral-1, #fff) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  display: flex;
  line-height: 28px !important;
  letter-spacing: -0.48px !important;
}

.subTitle {
  color: var(--neutral-1, #fff) !important;
  font-size: 18px !important;
  display: flex;
}

.text {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 16px !important;
  display: flex;
  margin-top: 4px;
}

.card {
  width: 100%;
  padding: 15px;
  border-radius: 10px !important;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08)) !important;
  margin-top: 20px !important;
}

.btn {
  border-radius: 10px !important;
  border: 2px solid white !important;
  background: transparent !important;
  display: flex !important;
  color: white !important;
  margin-top: 15px !important;
}

.count {
  color: var(--neutral-1, #fff) !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  display: flex;
  margin-top: 3px;
}
