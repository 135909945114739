.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  padding: 0px;
  height: 100%;
}

.heading {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;

  /* H5/bold */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 150% */
}

.subContainer {
  width: "100%";
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center !important;

  /* Body/medium */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 128.571% */
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 378px;
  height: 165px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  gap: 2vh;
}

.connect {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.divider {
  width: 378px;
}

.dividerText {
  color: #77767c;
  font-family: "Satoshi-Variable";
}
