.weeklyCardContainer {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 38px;

}

.dateContainer {
    margin-top: 46px;
    text-align: left;
}

.dateText {
    font-family: 'Red Hat Display' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #201F28 !important;
}

.dayCardContainer {
    width: 47px;
    height: 68px;
    display: flex !important;
    position: relative;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #E9E9E9;
    border-radius: 4px;
}

.monthCardContainer {
    width: 80px;
    height: 68px;
    display: flex !important;
    position: relative;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #E9E9E9;
    border-radius: 4px;
}


.monthCardContainer :hover{
    cursor: pointer;
}
.monthCardContainerSelected :hover{
    cursor: pointer;
}


.dayCardContainer :hover{
    cursor: pointer;
}
.dayCardContainerSelected :hover{
    cursor: pointer;
}
.verticalLineEmpty {
    width: 3px;
  height: 100%;
  background-color: white; /* Set the desired color for the vertical line */
  position: absolute;
  top: 0;
  
 /* Adjust this value to position the line after the card */
  right: -7px; /* Adjust this value to position the line to the right of the card content */
  z-index: 1
  }
.verticalLine {
  width: 3px;
  height: 100%;
  background-color: #464092; /* Set the desired color for the vertical line */
  position: absolute;
  top: 0;
 /* Adjust this value to position the line after the card */
  right: -7px; /* Adjust this value to position the line to the right of the card content */
  z-index: 1;
  
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.dayCardContainerSelected {
    width: 47px;
    height: 68px;
    position: relative;
    background-color: #464092;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px;
    font-size: 24px !important;
}

.monthCardContainerSelected {
    width: 80px;
    height: 68px;
    position: relative;
    background-color: #464092;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px;
    font-size: 24px !important;
}

.cardList {
    width: 100%;
    margin-top: 16px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between !important;
    /* background-color: pink; */
}

.cardText {
    font-family: 'Red Hat Display' !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #000000;
}

.cardTextSelected {
    font-family: 'Red Hat Display' !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #ffffff;
}