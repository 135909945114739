.mainContainer {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  /* height: 100px; */
}
.flexRow {
  display: flex;
  align-items: center;
  gap: 5px;
}
.moduleTitleMainPage {
  color: var(--light-primary-bg, #9747ff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 1.4px !important;
  text-transform: uppercase !important;
}
.directionText {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.moduleTitle {
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.leftSideBar {
  width: 25%;
  padding: 20px;
  height: 100%;
}
.mainPage {
  height: 100%;
  padding: 20px;
  width: 50%;
}
.mainPageVideo {
  display: flex;
  height: 200px;
  padding: 48px 526px 380px 24px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  opacity: 0.8;
  margin-bottom: 20px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.rightSideBar {
  width: 25%;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.moduleTopicText {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  color: #fff !important;
  font-size: 32px !important;
  text-align: left !important;
  font-style: normal !important;
  margin-bottom: 10px;
  font-weight: 700 !important;
  line-height: 40px !important; /* 125% */
}
.moduleTitleDescription {
  color: #fff !important;
  opacity: 0.8 !important;
  /* H5/medium */
  font-size: 16px !important;
  text-align: left !important;
  font-style: normal !important;
  margin-bottom: 10px;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.moduleTitleDetails {
  text-align: left !important;
  color: #fff !important;
  margin-top: 10px !important;

  /* H5/regular */
  font-size: 16px !important;
  font-style: normal !important;
  margin-bottom: 10px;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  opacity: 0.5 !important;
}
.transcriptTitle {
  color: var(--light-white, #fff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: -0.4px !important;
  opacity: 0.7 !important;
}
