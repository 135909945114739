* {
  font-weight: 700;
  font-family: "Satoshi-Variable";
}
.headerContainer {
  display: flex;
  padding: 20px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.menuText {
  color: #fff !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 9px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 177.778% */
  letter-spacing: 0.9px !important;
  text-transform: uppercase !important;
}
.backIconContainer {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.headerText {
  color: var(--neutral-1, #fff) !important;
  width: 70vw;
  text-align: left !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 40px !important; /* 133.333% */
  letter-spacing: -0.6px !important;
}

img {
  margin-top: 0px !important;
}

@media only screen and (max-width: 1439px) and (min-width: 1201px) {
}

@media only screen and (max-width: 1200px) and (min-width: 769px) {
}

@media only screen and (max-width: 1065px) and (min-width: 769px) {
}
