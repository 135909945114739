.drawer {
  position: fixed;
  top: 0;
  right: -300px; /* Start off-screen */
  width: 300px;
  display: none;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-out;
  background: #030204;
  display: flex;
  padding: 4vh 2vw 0px 2vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}
.teamTitle{
  align-self: center;
  color: #fff !important;
  font-size: 16px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.drawerOpen {
  position: fixed; /*position fixed and top 0 for full screen */
  /* top: 0; */
  height: 100%;
  overflow-y: scroll;
  right: 0; /* Start off-screen */
  width: 35%;
  bottom: 0;
  /* height: 88vh; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-out;
  background: #030204;
  display: flex;
  padding: 4vh 2vw 0px 2vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  z-index: 2;
  align-self: stretch;
}
.sectionHeaderText {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 100% */
  letter-spacing: 1.6px !important;
  text-transform: uppercase !important;
}
.meetingInfoHeading {
  color: #fff !important;
  text-align: left !important;
  /* H5/medium */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.meetingInfoSuggestion {
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: left !important;
  /* Body/regular */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 128.571% */
}
.chartContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2vw;
  padding: 3vh 0;
  align-self: stretch;
}
.graphTitle {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 18px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important; /* 100% */
  letter-spacing: 0.25px !important;
}
.graphXaxis {
  color: rgba(255, 255, 255, 0.32) !important;
  text-align: left !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 10px !important;
  font-style: normal !important;
  margin-left: 5px !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 175% */
  letter-spacing: 0.8px !important;
  text-transform: uppercase !important;
}
.sectionHeader {
  display: flex;
  height: 36px;
  align-items: center;
  margin-top: 32px;
  gap: 10px;
  align-self: stretch;
}
.suggestionBarContainer {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll !important;
  gap: 10px;
  align-items: center;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important; /* IE and Edge */
}
.suggestionBarContainer::-webkit-scrollbar {
  display: none !important;
}
.meetingInfoInnerContainer{
  padding: 30px;
  border-radius: 12px;
background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

/* Elevations/E2 */
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}
.buttonContainer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  cursor: pointer;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
}
.graphHeading {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  text-align: left !important;

  /* Capitol Heading 25 */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.overviewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.description {
  height: 48vh;
  overflow-y: auto;
  color: rgba(255, 255, 255, 0.5) !important;
  /* H5/medium */
  font-size: 16px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
  margin-top: 1vh;
}
.meetingStartTime {
  color: #57f9c8 !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important; /* 123.077% */
  letter-spacing: 1.3px !important;
  text-transform: uppercase !important;
}
.meetingTitle {
  color: #fff !important;
  font-size: 32px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 40px !important; /* 125% */
}
.meetingDetails {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2vh;
  align-self: stretch;
  flex-wrap: wrap;
}
.meetingDuration {
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.meetingDate {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: left !important;

  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}
.meetingInfoContainer {
  /* background-color: pink; */
  width: 100%;
}
.description::-webkit-scrollbar {
  display: none;
}
.bottomContainer {
  display: flex;
  padding: 20px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 1vw;
  align-self: stretch;
  border-top: 1px solid var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
}
.buttonContainer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16));
  cursor: pointer;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
}
.joinMeeting {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-background, #f6f6f6);
  color: var(--light-text-primary, #120029) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  cursor: pointer;
  border: none !important;
  line-height: normal !important;
}
.joinMeeting:hover {
  border-radius: 4px;
  background: var(--neutrals-background, #f6f6f6);
  color: var(--light-text-primary, #120029) !important;
}
@media screen and (max-width: 1300px) {
  .description {
    height: 38vh !important;
  }
  .meetingTitle {
    font-size: 26px !important;
  }
}
