.container {
  padding: 0vh 0px 0 20px !important;
  margin: 0px;
  display: flex;

  width: 100%;
}

.myTaskButton {
  background-color: #eeedf7 !important;
  color: #201f28 !important;
  width: 128px !important;
  height: 40px !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.myTaskButton:hover {
  background-color: inherit;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 0vh 0vh 0vh;
}

.title {
  text-align: left;
  color: #201f28;
  font-family: "Red Hat Display" !important;
}

.backButton {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1vw;
}

.secondaryContainer {
  width: 100%;
  padding: 0px !important;
  margin-top: 50px;
  border-radius: 4px;
}

.libraryContainer {
  width: 100%;
  padding: 0px;
  box-shadow: none;
  background-color: #030204;

  border-radius: 4px;
  box-shadow: none;
  padding: 0px !important;
}

.libraryFlex {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  padding: 0px !important;
}

.menuSection {
  width: 23%;
  padding-top: 3vh;
}
.MenuListContainer {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  margin-top: 5px;
}
.MenuListContainerActive {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
}
.MenuItem {
  color: var(--neutral-1, #fff) !important;

  /* H5/bold */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
}
.userName {
  color: #fff !important;
  text-align: center !important;

  /* H3/medium */
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important; /* 133.333% */
}
.editText {
  color: var(--light-primary-bg, #ffffff0a) !important;
  text-align: left !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  cursor: pointer;
}
.avatarContainer {
  display: flex;
  padding: 20px;
  margin-bottom: 15px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px 0px 0px 0px;
  background: linear-gradient(
    133deg,
    #51ffe0 -86.89%,
    rgba(114, 128, 179, 0.28) -20.19%,
    rgba(97, 189, 255, 0) 43.79%
  );
}

.purpleBg {
  position: absolute;
  background-color: #4a42ad;
  width: 276px;
  height: 100px;
  border-radius: 4px 0px 0px 0px;
  z-index: 0;
}

.noBg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 276px;
  height: 200px;
  z-index: 0;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  height: 54px;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
}

.listItem p {
  margin-left: 12px;
  color: #77767c;
  font-family: "Satoshi-Variable";
  font-weight: 500;
  font-size: 16px;
}

.listItemSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  height: 54px;
  width: 100%;
  background-color: #fafafa;
  border-right: 3px solid #807bc6;
  cursor: pointer;
}

.listItemSelected p {
  margin-left: 12px;
  color: #201f28;
  font-family: "Satoshi-Variable";
  font-weight: 500;
  font-size: 16px;
}

.settingSection {
  width: 75%;
  height: 100%;
  padding: 32px;
  background: linear-gradient(
    90deg,
    #151515 -122.7%,
    rgba(73, 73, 73, 0) 45.67%
  );
}

@media only screen and (max-width: 1065px) and (min-width: 876px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .menuSection {
    width: 50% !important;
  }

  .cardSection .titleContainer {
    padding: 0px !important;
  }

  .cardListContainer {
    height: 90% !important;
  }

  .settingSection {
    height: unset !important;
  }

  .menuSection {
    height: unset !important;
  }
}

@media only screen and (max-width: 876px) and (min-width: 720px) {
  .titleContainer {
    justify-content: flex-start;
    gap: 20px !important;
  }

  .secondaryContainer {
    width: 92% !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .menuSection {
    width: 50% !important;
  }

  .cardSection .titleContainer {
    padding: 0px !important;
  }

  .cardListContainer {
    height: 90% !important;
  }

  .settingSection {
    height: unset !important;
  }

  .menuSection {
    height: unset !important;
  }
}
