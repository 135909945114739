.card {
  max-width: 279;
  background-color: #f4f5f9;
  box-shadow: none;
  height: 160px;
  z-index: -1;
}

.favorites {
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
  height: 178px;
  border-radius: 4px;
  z-index: 10;
}

.favorites:hover {
  position: relative;
  border: 2px solid #3b358a;
  padding: 0px !important;
  margin: 0px !important;
  height: 178px;
  z-index: 10;
  width: fit-content;
  border-radius: 4px;
  z-index: 10;
}

.documentCont {
  padding: 0px !important;
  margin: 0px !important;
}

.favorites:hover .favIconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  z-index: 10;
  top: 8px;
  right: 8px;
  box-shadow: none;
  cursor: pointer;
}

.favIcon {
  display: none;
}

.favorites:hover .favIcon {
  margin: 0px !important;
  display: block;
}

.cardTypePage {
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.productCard {
  background-color: #eeedf7;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: end;
  align-self: flex-end;
  z-index: -1 !important;
}

.cardTitle {
  color: #201f28;
}

.productCard img {
  color: #807bc6;
  height: 53px;
  width: 66px;
  margin-top: 11px !important;
  margin-right: 21px;
  opacity: 0.2;
}

.subtext {
  height: 38px;
  text-overflow: ellipsis;
  color: #636269;
}
