.container {
  height: fit-content;
  display: flex !important;
  justify-content: start;
  align-self: center;
  flex-direction: row;
  gap: 12px;
  padding: 0px !important;
}

.textContainer {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  padding: 0px !important;
  gap: 3px;
}

.description {
  color: rgba(255, 255, 255, 0.6) !important;
  text-align: left !important;
  /* H5/regular */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
}

img {
  margin-top: 4px;
}

.heading {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 100% */
  letter-spacing: 1.6px !important;
  text-transform: uppercase !important;
}
