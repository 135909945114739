.mainContainer {
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 7px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 20px;
}
.videoInfoContainer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  /* justify-content: center; */
  gap: 12px;
  width: 100%;

  align-self: stretch;
}
img {
  margin: 0 !important;
}
.flexBetween {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
}
.videoInfo {
  color: var(--dark-inverted-bg-100, #ffffff92) !important;
  text-align: center !important;

  /* H5/regular */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
}
.videoTitle {
  color: #fff !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 28px !important; /* 140% */
}
.flexRow {
  display: flex;
  gap: 8px;
}
