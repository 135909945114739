.switch {
  display: flex;
  width: 60px;
  height: 30px;
  background-color: black;
  align-items: center;
  flex-direction: row;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.switchOn {
  background-color: #4caf50;
}

.switchOff .image {
  filter: grayscale(100%);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s;
}

.meetingControls {
  width: 100%;
  height: 50px !important;
  background: linear-gradient(
    180deg,
    #4c0f9b -16.07%,
    rgba(53, 17, 100, 0) 66.07%
  );
  display: flex !important;
  flex-direction: column;
  padding: 5px;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.topControls {
  width: 90%;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-top: 8px;
}

.bottomControls {
  width: 90%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.iconBox {
  width: 24px;
  height: 26px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 32px;
}
