.container {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  gap: 8px;
  align-self: stretch;
  border-radius: 13px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  border: 2px solid;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

.title {
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch; */
  text-align: left !important;
  /* overflow: hidden; */
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  /* text-overflow: ; */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
  width: max-content;
}
.type {
  color: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24));
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
}
