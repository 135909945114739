.listItem {
  background-color: #fafafa;
  border-radius: 4px;
  height: 77px;
}
.listCard {
  gap: 8px;
  border-radius: 4px;
}

.listItemRecent {
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  padding-right: 0px !important;
  padding-left: 16px;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.recentSecondary {
  width: fit-content;
  font-size: 10px;
  font-weight: 500 !important;
  display: flex;
  justify-self: unset;
  align-items: flex-start;
}

.recentSecondaryBox {
  display: flex;
  justify-content: start;
  align-items: flex-start !important;
  margin-top: 5px;
  font-size: 13px !important;
  font-weight: 500 !important;
  gap: 2px;
}

.tableListItem {
  background-color: #ffff;
}

font {
  display: flex;
  justify-content: start;
  align-items: flex-start !important;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.MuiListItem-root {
  text-align: left !important;
}

@media only screen and (max-width: 1439px) and (min-width: 1201px) {
  .primaryText {
    font-size: 80% !important;
  }

  .secondaryText {
    font-size: 80% !important;
  }

  .recentSecondary font {
    font-size: 80% !important;
  }

  .icon {
    height: 14px !important;
    width: 14px !important;
  }

  .listItem {
    padding: 10px 10px 10px 10px !important;
  }
  .listCard {
    padding: 10px 10px 10px 10px !important;
  }

  .listItemRecent {
    padding: 10px 10px 10px 10px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1065px) {
  .primaryText {
    font-size: 70% !important;
  }

  .secondaryText {
    font-size: 70% !important;
  }

  .recentSecondaryBox font {
    font-size: 70%;
  }

  .icon {
    height: 12.25px !important;
    width: 12.25px !important;
  }

  .listItem {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .listCard {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .listItemRecent {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 1065px) and (min-width: 834px) {
  .primaryText {
    font-size: 65% !important;
  }

  .secondaryText {
    font-size: 65% !important;
  }

  .recentSecondaryBox font {
    font-size: 65%;
  }

  .icon {
    height: 10.5px !important;
    width: 10.5px !important;
  }

  .listItem {
    padding-top: 2px !important;
    padding-bottom: 6px !important;
    padding-left: 12px !important;
    padding-right: 6px !important;
  }
  .listCard {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 6px !important;
  }

  .listItemRecent {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 4px !important;
  }

  .listItemTable .primaryText {
    font-size: 75% !important;
  }

  .listItemTable .secondaryText {
    font-size: 75% !important;
  }
}

@media only screen and (max-width: 834px) and (min-width: 720px) {
  .primaryText {
    font-size: 50% !important;
  }

  .secondaryText {
    font-size: 45% !important;
  }

  .recentSecondaryBox font {
    font-size: 45%;
  }

  .icon {
    height: 7px !important;
    width: 7px !important;
  }

  .checkBox {
    height: 25px !important;
    width: 25px !important;
  }

  .listItem {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    height: 50px;
  }
  .listCard {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 6px !important;
  }

  .listItemRecent {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    padding-right: 4px !important;
  }

  .listItemTable .primaryText {
    font-size: 70% !important;
  }

  .listItemTable .secondaryText {
    font-size: 70% !important;
  }
}
