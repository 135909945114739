.attachedDocument {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 12px;
  gap: 16px !important;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.name {
  text-align: left;
  font-family: "Red Hat Display" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #201f28 !important;
}

.description {
  text-align: left;
  font-family: "Satoshi-Variable" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #636269 !important;
}

.icon {
    height: 14px !important;
    width: 14px !important;
    color: #3B358A !important;
}

.chipDefault {
    background-color: #f6f6f6 !important;
    color: #3B358A !important;
    border: none !important;
    width: fit-content !important;
    height: 26px !important;
    font-size: 13px !important;
    font-family: "Satoshi-Variable" !important;
    font-weight: 400 !important;
    padding: 4px 8px 4px 8px !important;
    border-radius: 30px !important;
  }
