.talkingPointsHeading {
  color: var(--neutrals-muted, #77767c) !important;
  text-align: left !important;
  /* Body/Medium_14 */
  font-size: 14px !important;
  width: 80%;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 22.4px */
  letter-spacing: 0.07px !important;
}
.medicineName {
  color: var(--light-white, #fff) !important;
  text-align: left !important;

  /* Headings/H4_16 */
  font-family: Red Hat Display !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 0.04px !important;
}
.productContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  gap: 24px;
  padding: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));

  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.editButton {
  display: flex;
  height: 48px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--Neutrals-Primary_white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  border: none;
  line-height: normal !important;
  cursor: pointer;
}
.editButton:hover {
  border-radius: 4px;
  background: var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));

  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
}
.managerProductContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  gap: 24px;
  padding: 20px;

  /* Elevations/E2 */
}
.talkingPointContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.talkingPointTitle {
  color: var(--neutrals-muted, #77767c);
  text-align: left !important;

  /* Body/Medium_14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.07px;
}
.talkingPointDescription {
  color: var(--light-white, #fff) !important;
  width: 75%;
  /* Body/Medium_14 */
  text-align: left !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 22.4px */
  letter-spacing: 0.07px !important;
}
.cancelButton {
  display: flex;
  height: 48px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #fff !important;
  /* Button_shadow */
  box-shadow: 0px 8px 30px 0px rgba(74, 66, 173, 0.12);
  color: var(--Neutrals-Primary_white, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  border: none;
  line-height: normal !important;
  cursor: pointer;
}
.editModetalkingPointHeading {
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  color: var(--neutrals-muted, #77767c) !important;
  text-align: left !important;
  /* Body/Medium_14 */
  border: none;
  width: 80%;
  padding: 10px 16px;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 22.4px */
  letter-spacing: 0.07px !important;
}

.sectionHeader {
  color: rgba(255, 255, 255, 0.8) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 1.4px !important;
  text-transform: uppercase !important;
}
