.container {
  display: flex;
  width: 95%;
  padding: 16px;
  margin-top: 25px;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  background: var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04));
  /* Elevations/E2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.description {
  color: #ffffffcc;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi Variable;
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.25px;
}
.title {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-bottom: 15px;
  /* Captiol Heading 50 */
  font-family: Satoshi Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.timeStamp {
  color: rgba(255, 255, 255, 0.5) !important;

  /* Capitol Heading 25 */
  font-family: Satoshi Variable;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-align: left !important;
  line-height: 14px !important; /* 140% */
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
