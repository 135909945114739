.meetingContainer {
  width: 100%;
  height: 100vh;
  padding: 0px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-self: center;
  /* overflow-y: scroll; */
  flex-direction: row;
  overflow-x: hidden;
}

.videoCallContainer {
  width: 70% !important;
  height: 100%;
  background-color: gray;
  padding: 0px !important;
}

.meetingSidebarContainer {
  width: 30% !important;
  /* height: 100% !important; */
  background-color: black;
  padding: 0px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}

.rectangleBar {
  width: 100%;
  height: 21px;
  background-color: #3c3c3d;
  padding: 0px !important;
}
