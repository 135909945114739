.container {
  border-radius: 12px;
  width: 100%;
  gap: 18px;
  /* margin-top: 20px; */
  height: 18vh;
  position: relative;
  background: radial-gradient(
    90.94% 93.22% at 50% 50%,
    rgba(42, 209, 149, 0.21) 0%,
    rgba(40, 202, 144, 0) 100%
  );
  box-shadow: 0px 4px 8px 0px #0000001a;
  /* background: #ffffff0a; */
}
.containerNegative {
  border-radius: 12px;
  width: 100%;
  gap: 18px;
  /* margin-top: 20px; */
  height: 18vh;
  position: relative;
  background: radial-gradient(
    90.94% 93.22% at 50% 50%,
    rgba(229, 26, 63, 0.44) 0%,
    rgba(229, 26, 63, 0) 100%
  );
  box-shadow: 0px 4px 8px 0px #0000001a;
  /* background: #ffffff0a; */
}
.title {
  color: rgba(255, 255, 255, 0.5) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  padding: 0 10px;
  font-size: 12px !important;
  margin-top: 05px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important; /* 133.333% */
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
.containerAverage {
  border-radius: 12px;
  width: 100%;
  gap: 18px;
  /* margin-top: 20px; */
  height: 18vh;
  position: relative;
  background: radial-gradient(
    90.58% 93.72% at 50% 50%,
    rgba(244, 190, 79, 0.31) 0%,
    rgba(244, 190, 79, 0) 100%
  );
  box-shadow: 0px 4px 8px 0px #0000001a;
  /* background: #ffffff0a; */
}
.flexContainer {
  display: flex !important;
  width: 100%;
  margin-top: 20px !important;
  height: 100%;
  align-items: center !important;
}
.heading {
  color: #fff !important;
  text-align: left !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 133.333% */
  letter-spacing: 0.25px !important;
}
.description {
  color: rgba(255, 255, 255, 0.5) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Satoshi Variable;
  font-size: 13px !important;
  font-style: normal !important;
  text-align: left !important;
  margin-top: 5px !important;
  font-weight: 400 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 0.25px !important;
}
.innerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 1023px) and (max-width: 1230px) {
  .container {
    height: 18vh;
  }
  .title {
    font-size: 10px !important;
  }
  .containerNegative {
    height: 18vh;
  }
  .containerAverage {
    height: 18vh;
  }
  .heading {
    font-size: 16px !important;
    width: 90%;
  }
  .description {
    font-size: 12px !important;
    width: 90%;
  }
}
