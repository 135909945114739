.tabContainer {
  display: flex;
  padding: 12px 20px 0px 20px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08));
}
.tab {
  display: flex;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  /* border-bottom: 2px solid var(--dark-inverted-bg-100, #fff); */
}
.tabText {
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.25px !important;
}
.countContainer {
  display: flex;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24));
  color: var(--dark-inverted-bg-100, #fff) !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 0.11px !important;
  text-transform: uppercase !important;
}
